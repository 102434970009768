import { useContext } from 'react';
import PageWrapper from './global/PageWrapper';
import SystemRequirements from './download/SystemRequirements';
import DownloadInstructions from './download/DownloadInstructions';
import AppContext from '../contexts/app/app-context';
import { GenericModal } from '../components/modal';
import { DownloadFormWrapper } from '../forms';
import { StyledDownloadButton, StyledSystemReqOpener } from '../components/styled/StyledDownloadPage';

const Download = () => {
  const context = useContext(AppContext);
  const { DETAILS } = context;
  const { company } = context.state;
  const getDownloadLink = `${window.location.host}/download/start`;

  return (
    <PageWrapper>
      <div className='download page-wrapper'>
        <div className='button-holder'>
          <GenericModal
            modalClass='download-form'
            openButton={
              <StyledDownloadButton>
                <span>Download The IPG v{DETAILS.version}</span>
              </StyledDownloadButton>
            }
          >
            <DownloadFormWrapper downloadLink={getDownloadLink} ipgCompany={company.name} version={DETAILS.version} />
          </GenericModal>

          <div className='releaseDate'>
            <span>Released: {DETAILS.released}</span>
          </div>

          <GenericModal
            modalClass='sysreqs'
            openButton={
              <StyledSystemReqOpener>
                <span>System Requirements</span>
              </StyledSystemReqOpener>
            }
          >
            <SystemRequirements />
          </GenericModal>
        </div>

        <DownloadInstructions />

        <p className='information'>
          The Interactive Product Guide (IPG) is an exclusive sales tool for Spiral. It features equipment listings,
          specifications, features, benefits, and many demonstration and instructional videos. Once downloaded, this
          tool does not require Internet connectivity to function. Simply download the IPG from this page onto your
          notebook, tablet, or PC device and unzip the files. Please see the System Requirements and Instructions for
          more details.
        </p>
      </div>
    </PageWrapper>
  );
};
export default Download;
