import styled from 'styled-components';
import { darken } from 'polished';

export const StyledProductVideoList = styled.div`
  box-sizing: border-box;
  padding: 1rem;
`;

export const StyledProductVideoListItem = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.primary};
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.tablet_rg}) {
    display: block;
    width: 100%;
  }
`;

export const StyledProductVideoTitle = styled.div`
  flex: 1 1 auto;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.tablet_rg}) {
    display: block;
    width: 100%;
  }
`;

export const StyledProductVideoButton = styled.button`
  background-color: ${({ theme }) => theme.primary};
  border: 0.1rem solid ${({ theme }) => darken(0.05, theme.primary)};
  border-radius: 3rem;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 1rem;
  margin: 0 0 0 0.5rem;
  padding: 0.5rem 1rem;
  transition: 0.25s linear all;
  span {
    &:before {
      content: '\f144';
      font-family: 'FontAwesome';
      margin-right: 6px;
    }
  }
  &:hover {
    opacity: 0.8;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.tablet_rg}) {
    border-radius: 0.4rem;
    display: block;
    margin: 0.3rem 0 0;
    width: 100%;
  }
`;
