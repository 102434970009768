import { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import CatalogContext from '../../contexts/catalog/catalog-context';
import CategoryGridLayout from './category-grid/CategoryGridLayout';
import { StyledTitleH1Category } from '../../components/styled/StyledTitle';

const CategoryListing = ({ category }) => {
  const context = useContext(CatalogContext);
  const { catalog } = context.state;
  const categoryInfo = catalog.categories[category.category_id] || {};

  const categoryRows = useMemo(() => {
    const categoryRows = [];
    const gridRowSlots = 6;
    let newCategories = Object.values(category.categories).slice();
    const totalItems = newCategories.length;
    const rows = Math.ceil(totalItems / gridRowSlots);
    const itemsPerRow = Math.ceil(totalItems / rows);

    do {
      categoryRows.push(newCategories.splice(0, itemsPerRow));
    } while (newCategories.length > 0);

    return categoryRows;
  }, [category.categories]);

  return (
    <div className='category-listing-page'>
      <StyledTitleH1Category>{categoryInfo.name || 'All Categories'}</StyledTitleH1Category>
      {categoryRows.map((categories, index) => (
        <CategoryGridLayout key={index} categories={categories} />
      ))}
    </div>
  );
};
export default CategoryListing;

CategoryListing.propTypes = {
  category: PropTypes.object.isRequired,
};
