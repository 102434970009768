import { animated, config, useTransition } from '@react-spring/web';
import PropTypes from 'prop-types';
import Portal from '../Portal';

const Modal = ({ allowOverlayClose, children, className, modalClassName, closeModal, enableCloseBar, isVisible }) => {
  const overlayClose = () => allowOverlayClose && closeModal();

  const transitions = useTransition(isVisible, {
    from: { opacity: 0, scale: 0.7, bgOpacity: 0 },
    enter: { opacity: 1, scale: 1, bgOpacity: 0.5 },
    leave: { opacity: 0, scale: 0.1, bgOpacity: 0 },
    config: config.wobbly,
  });

  return transitions(
    (styles, item) =>
      item && (
        <Portal id='modal'>
          <div className={`modal-container ${className}`}>
            <div className={`modal ${modalClassName}`}>
              <animated.div
                className='modal-window'
                style={{
                  opacity: styles.opacity.to((opacity) => opacity),
                  transform: styles.scale.to((scale) => `scale(${scale})`),
                }}
              >
                {enableCloseBar && (
                  <div className='modal-close-bar'>
                    <div onClick={closeModal} className='modal-close-button' />
                  </div>
                )}
                <div className='modal-body'>{children}</div>
              </animated.div>
            </div>
            <animated.div
              onClick={overlayClose}
              className='modal-overlay'
              style={{
                opacity: styles.bgOpacity.to((bgOpacity) => bgOpacity),
              }}
            />
          </div>
        </Portal>
      )
  );
};
export default Modal;

Modal.propTypes = {
  allowOverlayClose: PropTypes.bool,
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func.isRequired,
  className: PropTypes.string,
  enableCloseBar: PropTypes.bool,
  isVisible: PropTypes.bool.isRequired,
};

Modal.defaultProps = {
  allowOverlayClose: true,
  className: '',
  enableCloseBar: true,
  isVisible: false,
};
