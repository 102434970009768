const SystemRequirements = () => (
  <div className='sysreqs-instructions'>
    <div className='optimal'>
      <p className='heading'>Optimal Requirements:</p>
      <ul>
        <li>
          <span>Windows 10 or macOS Mojave</span>
          <ul>
            <li>Latest updates installed</li>
          </ul>
        </li>
        <li>
          <span>Modern Web Browser Latest Version</span>
          <ul>
            <li>Chrome</li>
            <li>Firefox</li>
            <li>Safari</li>
            <li>Edge</li>
          </ul>
        </li>
      </ul>
    </div>
    <div className='minimal'>
      <p className='heading'>Minimal Requirements:</p>
      <ul>
        <li>
          <span>Window 7 or OSX Mavericks</span>
          <ul>
            <li>Lastest updates installed</li>
          </ul>
        </li>
        <li>
          <span>Web Browser</span>
          <ul>
            <li>Chrome v21+</li>
            <li>Firefox v28+</li>
            <li>Safari v6+</li>
            <li>Edge v12+</li>
            <li>Explorer v11</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
);
export default SystemRequirements;
