import { Children, useState } from 'react';
import PropTypes from 'prop-types';
import { StyledTabBar, StyledTabBarTabItem, StyledTabBarTabList, StyledTabMinimizer } from '../styled/tabs';

const TabsList = ({ activeTab, children, switchTabs }) => {
  const [minimize, setMinimize] = useState(false);
  const handleClick = () => setMinimize(!minimize);

  return (
    <StyledTabBar minimize={minimize}>
      <StyledTabMinimizer minimize={minimize} onClick={handleClick} />
      <StyledTabBarTabList>
        {Children.map(children, (child, i) => (
          <StyledTabBarTabItem onClick={() => switchTabs(i)} key={i} isActive={i === activeTab}>
            <span>{child.props.label}</span>
          </StyledTabBarTabItem>
        ))}
      </StyledTabBarTabList>
    </StyledTabBar>
  );
};
export default TabsList;

TabsList.propTypes = {
  activeTab: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  switchTabs: PropTypes.func.isRequired,
};
