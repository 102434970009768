import { stringToPath, getItem, isObject, isInteger } from "../../utilities/generic";

// export all action keywords
export const AUTHENTICATED = "AUTHENTICATED";
export const UPDATE_VALUE = "UPDATE_VALUE";
export const TOGGLE_VISIBLE = "TOGGLE_VISIBLE";

const setAuthentication = (state, { isLoggedIn }) => ({ ...state, isLoggedIn });
const toggleVisible = (state) => ({ ...state, menuVisible: !state.menuVisible });

/**
 * Based on Formik's update function
 * @see https://github.com/jaredpalmer/formik/blob/master/src/utils.ts#L102
 */
const updateValue = (state, payload) => {
	const { field, value } = payload;
	let res = { ...state };
	let resVal = res;
	let i = 0;
	let pathArray = stringToPath(field);

	for (; i < pathArray.length - 1; i++) {
		const currentPath = pathArray[i];
		let currentObj = getItem(res, pathArray.slice(0, i + 1));

		if (currentObj && Array.isArray(currentObj)) {
			resVal = resVal[currentPath] = [...currentObj];
		} else if (currentObj && isObject(currentObj)) {
			resVal = resVal[currentPath] = { ...currentObj };
		} else {
			const nextPath = pathArray[i + 1];
			resVal = resVal[currentPath] = isInteger(nextPath) && Number(nextPath) >= 0 ? [] : {};
		}
	}

	// Return original object if new value is the same as current
	if ((i === 0 ? state : resVal)[pathArray[i]] === value) {
		return state;
	}

	if (value === undefined) {
		delete resVal[pathArray[i]];
	} else {
		resVal[pathArray[i]] = value;
	}

	// If the path array has a single element, the loop did not run.
	// Deleting on `resVal` had no effect in this scenario, so we delete on the result instead.
	if (i === 0 && value === undefined) {
		delete res[pathArray[i]];
	}

	return res;
};

export const appReducer = (state, action) => {
	const { type, payload } = action;
	switch (type) {
		case AUTHENTICATED:
			return setAuthentication(state, payload);
		case UPDATE_VALUE:
			return updateValue(state, payload);
		case TOGGLE_VISIBLE:
			return toggleVisible(state);
		default:
			return state;
	}
};
