import { animated, useTransition } from '@react-spring/web';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Message from '../../components/Message';
import QuoteActionBar from './components/QuoteActionBar';

const QuoteForm = ({ formActions }) => {
  const { values, setFieldValue, isSubmitting } = formActions;
  const { currentScreen, formScreenComponents, message, messageType } = values;

  // state to make screen slide in the proper direction
  const [isContinue, setIsContinue] = useState(true);
  const [prevScreen, setPrevScreen] = useState(currentScreen);

  useEffect(() => {
    if (prevScreen === currentScreen) {
      return;
    }
    setIsContinue(currentScreen > prevScreen);
    setPrevScreen(currentScreen);
  }, [currentScreen, prevScreen]);

  const transitions = useTransition(currentScreen, {
    keys: (item) => item,
    initial: { x: 0, position: 'relative' },
    from: { x: -1, position: 'relative' },
    enter: { x: 0, position: 'relative' },
    leave: { x: 1, position: 'absolute' },
  });

  const slideScreen = (xValue) => {
    const x = isContinue ? xValue * -100 : xValue * 100;
    return `translate3d(${x}%,0,0)`;
  };

  return (
    <Form>
      <Message message={message} type={messageType} show={!!messageType} />
      <QuoteActionBar values={values} updateValue={setFieldValue} />
      <div className='form-sections'>
        {transitions((styles, item) => {
          const CurrentFormSection = formScreenComponents[item];
          return (
            <animated.div
              className='section'
              key={item.key}
              style={{
                position: styles.position,
                transform: styles.x.to(slideScreen),
                width: '100%',
              }}
            >
              <CurrentFormSection isSubmitting={isSubmitting} values={values} updateValue={setFieldValue} />
            </animated.div>
          );
        })}
      </div>
    </Form>
  );
};

export default QuoteForm;

QuoteForm.propTypes = {
  formActions: PropTypes.object.isRequired,
};
