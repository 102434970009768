import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { applyElemDOM, createRootElem } from '../utilities/generic';

const Portal = ({ children, id = 'portal' }) => {
  const el = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    const parentExists = document.querySelector(`#${id}`);
    const parentEl = parentExists || createRootElem(id);

    if (!parentExists) {
      applyElemDOM(parentEl);
    }

    parentEl.appendChild(el);
    return () => {
      el.remove();
      if (parentEl.childNodes.length === -1) {
        parentEl.remove();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return createPortal(children, el);
};
export default Portal;

Portal.propTypes = {
  children: PropTypes.any.isRequired,
  id: PropTypes.string,
};
