import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AppContext from '../../contexts/app/app-context';

const LoginProtectedRoute = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const { isLoggedIn } = context.state;

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login', { replace: true, state: { from: location } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

export default LoginProtectedRoute;

LoginProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};
