import { animated } from '@react-spring/web';
import styled from 'styled-components';

export const StyledSwatchWrapper = styled.div`
  padding: 0.5rem 0;
`;

export const StyledSwatchTitle = styled.p`
  box-sizing: border-box;
  color: ${({ theme }) => theme.secondary};
  font-size: 1.35rem;
  font-weight: bold;
  margin: 0;
  padding-left: 0.5rem;
  text-align: left;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.tablet_rg}) {
    margin: 0 0 0.5rem;
  }
`;

export const StyledSwatches = styled.ul`
  display: block;
  font-size: 1rem;
  line-height: 1.2;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
`;

export const StyledSwatch = styled.li`
  box-sizing: border-box;
  display: inline-block;
  font-weight: bold;
  font-size: 1rem;
  margin: 0.6rem 0;
  min-width: 128px;
  padding: 0 0.4rem;
  width: 33.3333%;
  span {
    border-radius: 0.15rem;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    display: block;
    padding: 0.75rem 0.25rem;
    position: relative;
    text-align: center;
    &:hover,
    &:focus {
      opacity: 0.65;
    }
    &:after {
      border: 4px solid ${(props) => (props.isActive ? '#6fb427' : 'none')};
      box-shadow: ${(props) => (props.isActive ? 'inset 0 0 0 2px #ffffff' : '0')};
      border-radius: 0.15rem;
      box-sizing: border-box;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform: scale(${(props) => (props.isActive ? 1 : 0)});
      transition: 0.25s cubic-bezier(0.68, -0.55, 0.27, 1.55) transform;
      width: 100%;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.tablet_rg}) {
    display: inline-block;
    font-size: 1.1rem;
    margin: 0.5rem 0;
    min-width: 80px;
    width: 50%;
    span {
      font-size: 0.9rem;
      padding: 0.8rem 0.25rem;
    }
  }
`;

export const StyledSwatchHeroImageWrapper = styled(animated.div)`
  height: 100%;
  top: 0;
  width: 100%;
`;
