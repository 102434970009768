import PropTypes from 'prop-types';
import { Form, Field } from 'formik';
import Message from '../../components/Message';
import LoadingButton from '../../components/LoadingButton';
import Tooltip from '../../components/tooltip/Tooltip';
import TooltipOpener from '../../components/tooltip/TooltipOpener';
import TooltipContent from '../../components/tooltip/TooltipContent';

const LoginForm = ({ isSubmitting, setFieldValue, values }) => {
  const { message, messageType, rememberMe, revealPassword } = values;
  const inputType = revealPassword ? 'text' : 'password';
  const passwordIcon = revealPassword ? 'fa-eye' : 'fa-eye-slash';
  const rememberMeIcon = rememberMe ? 'fa-check-square-o' : 'fa-square-o';

  const toggleViewPassword = () => setFieldValue('revealPassword', !revealPassword);

  return (
    <Form>
      <Message message={message} type={messageType} show={!!messageType} />
      <div className='form-row'>
        <div className='form-item required'>
          <label htmlFor='password'>Password</label>
          <div className='password-wrapper'>
            <Field autoComplete='off' autoFocus={true} id='password' name='password' tabIndex={1} type={inputType} />
            <span className={`fa ${passwordIcon} fa-fw password-icon`} onClick={toggleViewPassword} />
          </div>
        </div>
      </div>
      <div className='form-row'>
        <div className='form-item checkbox'>
          <Field id='rememberMe' name='rememberMe' type='checkbox' tabIndex={2} checked={rememberMe} />
          <label htmlFor='rememberMe'>
            <span className={`fa ${rememberMeIcon} fa-fw checkbox-icon`} />
            <span>Remember Me</span>
          </label>
        </div>
      </div>
      <div className='form-row btn-row align-right'>
        <LoadingButton className={`primary`} name='login-button' type='submit' tabIndex={3} loading={isSubmitting}>
          <span>Login</span>
        </LoadingButton>
        <div className='forgot-password'>
          <Tooltip>
            <TooltipOpener className='ico-after'>
              <span className='fp-text'>Forgot your password?</span>
            </TooltipOpener>
            <TooltipContent className='bottom-side horz-rt'>
              If you have forgotten the password, please ask your manager for assistance.
            </TooltipContent>
          </Tooltip>
        </div>
      </div>
    </Form>
  );
};
export default LoginForm;

LoginForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
