import { useEffect, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CatalogContext from '../../contexts/catalog/catalog-context';

const useCarousel = ({ afterChange = null, beforeChange = null, initialSlide = null, ...rest }) => {
  const location = useLocation();
  const [isSliding, setIsSliding] = useState(false);
  const [allowClicks, setAllowClicks] = useState(false);

  const context = useContext(CatalogContext);
  const { carouselIndex } = context.state;
  const slideIndex = carouselIndex.pathname === location.pathname ? carouselIndex.slideIndex : 0;

  // create
  const userSettings = rest || {};
  const defaultSettings = {
    arrows: true,
    className: 'carousel-items',
    cssEase: 'ease-in-out',
    dots: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 300,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 960,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 680,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings = { ...defaultSettings, ...userSettings };

  // settings defaults and overrides
  settings.initialSlide = initialSlide !== null ? initialSlide : slideIndex;
  settings.beforeChange = () => {
    setIsSliding(true);
    beforeChange && beforeChange();
  };
  settings.afterChange = (currentSlide) => {
    setIsSliding(false);
    afterChange && afterChange(currentSlide);
  };

  useEffect(() => {
    setAllowClicks(isSliding !== true);
  }, [isSliding, setAllowClicks]);

  return { settings, allowClicks };
};

export default useCarousel;
