import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppContext from '../../../contexts/app/app-context';
import CatalogContext from '../../../contexts/catalog/catalog-context';
import { QUOTELIST_CLEARALL } from '../../../contexts/catalog/catalog-reducer';
import useModal from '../../../hooks/useModal';
import Modal from '../../../components/modal/Modal';

const QuoteSuccessModal = ({ callback }) => {
  const app = useContext(AppContext);
  const context = useContext(CatalogContext);

  const { company } = app.state;
  const spiralCompanies = ['Spiral', 'Bindomatic'];

  const { closeModal, isVisible } = useModal({
    allowEscClose: false,
    visible: true,
  });

  const closeCallback = () => {
    context.dispatch({ type: QUOTELIST_CLEARALL });
    callback();
    closeModal();
  };

  return (
    <Modal
      allowOverlayClose={false}
      className='success-modal'
      closeModal={closeCallback}
      enableCloseBar={false}
      isVisible={isVisible}
    >
      <div className='success-message'>
        <p className='title'>Success!</p>
        <p>
          {spiralCompanies.indexOf(company.name) > -1
            ? 'Your request was successfully submitted!'
            : 'Your request was successfully submitted and a member of our team will be in touch with you shortly!'}
        </p>
        <div className='modal-actions'>
          <Link to='/' onClick={closeCallback} className='btn primary reset'>
            <span>Reset IPG</span>
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default QuoteSuccessModal;

QuoteSuccessModal.propTypes = {
  callback: PropTypes.func.isRequired,
};
