import { animated } from '@react-spring/web';
import styled from 'styled-components';

export const StyledTabWrapper = styled.div``;

export const StyledTabSwitcher = styled.div`
  background: #ffffff;
  min-height: 90vh;
  overflow: hidden;
  position: relative;
`;

export const StyledTabContent = styled(animated.div)`
  left: 0;
  background: #ffffff;
  box-sizing: border-box;
`;
