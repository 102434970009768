import PropTypes from 'prop-types';
import spinner from '../../imgs/button-spinner.gif';

const Loading = ({ width }) => (
  <div className='loading-spinner' style={{ width: `${width}px` }}>
    <img alt='loading...' className='btn-spinner' src={spinner} />
  </div>
);
export default Loading;

Loading.propTypes = {
  width: PropTypes.number,
};
