import { Children, useState } from 'react';
import PropTypes from 'prop-types';

const Tooltip = ({ children, className = '' }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const toggleTooltip = () => {
    return setShowTooltip(!showTooltip);
  };

  const getChild = (type) => {
    let element = Children.toArray(children).filter((child) => child.type.displayName === type);
    return element[0];
  };

  return (
    <div
      className={`tooltip-container ${className}`}
      onMouseEnter={toggleTooltip}
      onMouseLeave={toggleTooltip}
      onTouchStart={toggleTooltip}
    >
      {getChild('TooltipOpener')}
      {showTooltip && getChild('TooltipContent')}
    </div>
  );
};
export default Tooltip;

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
