import { useReducer } from 'react';
import PropTypes from 'prop-types';
import CatalogContext from './catalog-context';
import { catalogReducer } from './catalog-reducer';
import { getCleanedPath } from '../../utilities/generic';

export const CatalogState = (props) => {
  const { catalog } = window;

  const [state, dispatch] = useReducer(catalogReducer, {
    catalog: catalog || {
      categories: [],
      map: { categories: {} },
      products: [],
    },
    carouselIndex: {},
    quoteList: [],
  });

  const actions = {};
  actions.getCurrentCategory = (pathname) => {
    let currentCategory = state.catalog.map;
    const parts = getCleanedPath(pathname.substring(2));

    if (parts.length === 0) {
      return currentCategory;
    }

    try {
      const lastPath = parts.pop();
      const cat = Object.values(state.catalog.categories).filter((cat) => cat.path === lastPath)[0];
      if (cat) {
        getCleanedPath(cat.structure.substring(2)).map((path) => {
          currentCategory = currentCategory.categories[`c${path}`];
        });
      }
      return currentCategory;
    } catch (err) {
      console.log({ err });
      // return some type of error
    }
  };

  actions.getCurrentProduct = (pathname) => {
    try {
      const lastPath = getCleanedPath(pathname.substring(2)).pop();
      return Object.values(state.catalog.products).filter((product) => product.path === lastPath)[0];
    } catch (err) {
      console.log(err);
    }
  };

  return <CatalogContext.Provider value={{ state, dispatch, actions }}>{props.children}</CatalogContext.Provider>;
};
CatalogState.propTypes = {
  children: PropTypes.node.isRequired,
};
