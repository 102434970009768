import { decode, encode } from 'html-entities';

/**
 * Gets the raw markup for React.
 *
 * @param      {object}  prop    The property from React
 */
export const getRawMarkup = (prop) => ({ __html: prop });

/**
 * Capitalize each word of the string passed in
 *
 * @param      {string}  str     The string
 * @return     {string}  Capitalized words
 */
export const capitalizeEachWord = (str) => {
  const string = typeof str !== 'string' ? str.toString() : str;
  return string
    .split(' ')
    .map((w) => {
      const word = w.trim();
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
};

/**
 * Find the ancestors of an elment based on a passed selector
 *
 * @param      {object}  el        Element to find the ancestor of
 * @param      {string}  selector  The selector on what type of ancestor to find
 * @return     {object}  The selected ancestor DOMNode
 */
export const findAncestor = (el, selector) => {
  while ((el = el.parentElement) && !el.matches(selector));
  return el;
};

/**
 * Creates a random identifier.
 *
 * @return     {string}  Random string identifier to be used
 */
const _createRandomId = () => Math.random().toString(36).substring(2, 10);

/**
 * Find element based on a query
 *
 * @param      {object}  el      Element to find based off of
 * @param      {string}  query   The selector on which element to find
 * @return     {object}  The selected DOMNode List (NodeList)
 */
export const findEl = (el, query) => {
  if (!el.id) {
    el.id = `randid_${_createRandomId()}`;
  }
  return el.querySelectorAll(`#${el.id} ${query}`);
};

/**
 * Creates a root element.
 *
 * @param      {string}       id      The identifier
 * @return     {HTMLElement}  The new HTML element with the provided id
 */
export const createRootElem = (id) => {
  const el = document.createElement('div');
  el.setAttribute('id', id);
  return el;
};

/**
 * Applies an element to the DOM.
 *
 * @param      {HTMLElement}  el      Element to append at the end of the DOM
 */
export const applyElemDOM = (el) => {
  document.body.insertBefore(el, document.body.lastElementChild.nextElementSibling);
};

/**
 * Find first element based on a query
 *
 * @param      {object}  el      Element to find based off of
 * @param      {string}  query   The selector on which element to find
 * @return     {object}  The selected DOMNode
 */
export const findFirstEl = (el, query) => {
  const nodeList = findEl(el, query);
  return nodeList.length ? nodeList[0] : false;
};

/**
 * Removes empty parts from path.
 *
 * @param      {string}  path    The path
 * @return     {array}  The cleaned path.
 */
export const getCleanedPath = (path) => path.split('/').filter(Boolean);

/**
 * Trim a delimiter off of the front and back of a string
 *
 * @param      {string}  str     The string
 * @param      {string}  delim   The delimiter
 * @return     {string}  The trimmed string
 */
export const trim = (str, delim) => _trim(str, delim, 'normal');

/**
 * Trim a delimiter off of the front of a string
 *
 * @param      {string}  str     The string
 * @param      {string}  delim   The delimiter
 * @return     {string}  The trimmed string
 */
export const ltrim = (str, delim) => _trim(str, delim, 'left');

/**
 * Trim a delimiter off of the back of a string
 *
 * @param      {string}  str     The string
 * @param      {string}  delim   The delimiter
 * @return     {string}  The trimmed string
 */
export const rtrim = (str, delim) => _trim(str, delim, 'right');

/**
 * Trim a delimiter that occurs multiple times within a string
 *
 * @param      {string}  str     The string
 * @param      {string}  delim   The delimiter
 * @return     {string}  The trimmed string
 */
export const mtrim = (str, delim) => _trim(str, delim, 'multi');

/**
 * Base function where all trim syntactic sugar functions resolve to for performing the actual trim logic.
 *
 * @param      {string}  str     The string
 * @param      {string}  delim   The delimiter
 * @param      {string}  type    The type of trim function to perform
 * @return     {string}  The cleaned string based on type of trim to perform
 */
const _trim = (str, delim, type) => {
  let regex = null;
  let replacement = '';
  const char = delim || '\\s';

  switch (type) {
    case 'left':
      regex = new RegExp(`^${char}+`, 'gi');
      break;
    case 'right':
      regex = new RegExp(`${char}+$`, 'gi');
      break;
    case 'multi':
      replacement = char === '\\s' ? ' ' : char;
      regex = new RegExp(`${char}+`, 'gi');
      break;
    case 'normal':
    default:
      regex = new RegExp(`^${char}+|${char}+$`, 'gi');
      break;
  }

  return str.replace(regex, replacement);
};

/**
 * Gets the safe encoded url.
 *
 * @param      {string}  str     The string
 * @return     {string}  The safe url.
 */
export const getSafeUrl = (str) => {
  const url = str
    .trim()
    .replace(/[&/]+/g, '-and-')
    .replace(/@+/g, '-at-')
    .replace(/\s+/g, '-')
    .replace(/(--+)/g, '-')
    .replace(/[^\w\d\s-_@&/]+/g, '');
  return encodeURIComponent(trim(url.toLowerCase(), '-'));
};

/**
 *
 * @param  {string} query The url query parameter string
 * @return {object} Query parameter object
 */
export const getUrlParams = (query) => {
  if (query.substring(0, 1) === '?') {
    query = query.substring(1);
  }

  const queryParts = query.split('&').reduce((queryParts, pair) => {
    let part = pair.split('=');
    let key = decodeURIComponent(part[0]);
    let value = decodeURIComponent(part[1]);
    queryParts[key] = value;
    return queryParts;
  }, {});

  return queryParts;
};

/**
 * Encode HTML Entities of any none ASCII character in a string
 *
 * @param      {string}  str     The string
 * @return     {string}  Encoded string
 */
export const htmlEntitiesEncode = (str) => encode(str, { mode: 'nonAscii' });

/**
 * Decode HTML Entities of any none ASCII character in a string
 *
 * @param      {string}  str     The string
 * @return     {string}  Decoded string
 */
export const htmlEntitiesDecode = (str) => decode(str);

/**
 * Check if Object has property
 *
 * @param {Object} obj
 * @param {string} prop
 */
export const hasOwnProp = (obj, prop) => (obj ? Object.prototype.hasOwnProperty.call(obj, prop) : false);

/**
 * Determines if value is an object.
 *
 * @param      {any}   obj     The object
 * @return     {boolean}  True if object, False otherwise.
 */
export const isObject = (obj) => obj !== null && typeof obj === 'object';

/**
 * Determines if the supplied object is an integer
 * @param {any} obj object to evaluate
 * @return     {boolean}  True if integer, False otherwise.
 */
export const isInteger = (obj) => String(Math.floor(Number(obj))) === obj;

/**
 * Converts a string to a path array based on the supplied delimiter
 * @param {string|string[]} path string to split
 * @param {string} delim delimiter to use to split string
 * @return {array} array of paths
 */
export const stringToPath = (path, delim = '.') => {
  if (typeof path !== 'string') {
    return path;
  }
  return path.replace(']', '').replace('[', delim).split('.');
};

/**
 * Get's an item in an array or object
 * @param {object|array} obj object to traverse to find item
 * @param {string} key item we are trying to find
 * @param {number} p iteration count
 * @return {object|array} value of the item in the array or object
 */
export const getItem = (obj, key, p = 0) => {
  const path = stringToPath(key);
  while (obj && p < path.length) {
    obj = obj[path[p]];
    p = p + 1;
  }
  return obj === undefined ? null : obj;
};

/**
 * Gets the new sort order from the user's drag 'n drop.
 *
 * @param      {array}   list         The list
 * @param      {object}   destination  The destination
 * @param      {object}   source       The source
 * @return     {boolean | array}  The new sort order.
 */
export const getNewSortOrder = (list, destination, source) => {
  // wasn't dropped on the destination no need to update
  if (!destination) {
    return false;
  }

  // dropped in the same position no need to update
  if (destination.droppableId === source.droppableId && destination.index === source.index) {
    return false;
  }

  return reorder(list, source.index, destination.index);
};

/**
 * Reorder an array with a given starting and ending index
 *
 * @param      {array}  list        The list
 * @param      {number}  startIndex  The start index
 * @param      {number}  endIndex    The end index
 * @return     {array}  Updated array with the new order
 */
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
