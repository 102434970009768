import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const StyledTable = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
`;

export const StyledTableHeader = styled.th`
  background-color: ${({ theme }) => theme.secondary};
  border-bottom: 1px solid ${({ theme }) => darken(0.1, theme.secondary)};
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: normal;
  padding: 0.6% 3%;
  text-align: left;
`;

export const StyledTableRowHeader = styled.th`
  background-color: ${({ theme }) => theme.table.label_odd || theme.primary};
  border-bottom: 1px solid ${({ theme }) => darken(0.05, theme.table.label_odd || theme.primary)};
  color: #ffffff;
  font-size: 1rem;
  font-weight: normal;
  padding: 1% 3%;
`;

export const StyledTableCell = styled.td`
  background-color: ${({ theme }) => theme.table.value_odd || lighten(0.4, theme.primary)};
  border-bottom: 1px solid ${({ theme }) => darken(0.05, theme.table.value_odd || lighten(0.4, theme.primary))};
  font-size: 1rem;
  font-weight: normal;
  padding: 1% 3%;
  & > p {
    margin: 0;
    & ~ p {
      margin: 5px 0 0;
    }
    & + p {
      margin: 5px 0 0;
    }
  }
  &[colspan] {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
  }
`;

export const StyledTableRow = styled.tr`
  text-align: left;
  &:nth-child(even) {
    ${StyledTableRowHeader} {
      background-color: ${({ theme }) => theme.table.label_even || lighten(0.05, theme.primary)};
      border-bottom: 1px solid ${({ theme }) => darken(0.05, theme.table.label_even || lighten(0.05, theme.primary))};
    }
    ${StyledTableCell} {
      background-color: ${({ theme }) => theme.table.value_even || lighten(0.5, theme.primary)};
      border-bottom: 1px solid ${({ theme }) => darken(0.05, theme.table.value_even || lighten(0.5, theme.primary))};
    }
  }
`;
