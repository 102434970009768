import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Image from '../../components/Image';
import { capitalizeEachWord } from '../../utilities/generic.js';
import { getRawMarkup } from '../../utilities/generic';
import { StyledTitleH1 } from '../../components/styled/StyledTitle';
import {
  StyledOption,
  StyledOptionTitle,
  StyledOptionWrapper,
  StyledOptions,
} from '../../components/styled/content/StyledOptionsList';

const OptionsList = ({ content, fallbackImage = '', product }) => {
  let { name, image } = content;
  const fallback = fallbackImage !== '' ? `imgs/${fallbackImage}` : null;
  let imgsrc = image !== null && image !== '' ? `imgs/products/options/${image}` : fallback;

  return (
    <Fragment>
      <StyledTitleH1>{name.replace('#name#', product.name)}</StyledTitleH1>
      {content.content !== '' && <div dangerouslySetInnerHTML={getRawMarkup(content.content)} />}
      <div className='product-section options-list'>
        <div className='left-col'>
          <div className='product-image'>
            <Image src={imgsrc} width={600} height={600} />
          </div>
        </div>
        <div className='right-col'>
          {content.type_filter.split(',').map((type) => {
            return (
              <StyledOptionWrapper key={type}>
                <StyledOptionTitle>
                  <strong>{capitalizeEachWord(type.split('_').join(' '))} Options:</strong>
                </StyledOptionTitle>
                <StyledOptions>
                  {product.options[type].map((option) => (
                    <StyledOption key={option.label}>
                      <span>{option.label}</span>
                    </StyledOption>
                  ))}
                </StyledOptions>
              </StyledOptionWrapper>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};
export default OptionsList;

OptionsList.propTypes = {
  content: PropTypes.shape({
    content: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    type_filter: PropTypes.string.isRequired,
  }),
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    options: PropTypes.object.isRequired,
  }),
  fallbackImage: PropTypes.string,
};
