import PropTypes from 'prop-types';
import CategoryGridRow from './CategoryGridRow';
import CategoryGridColumn from './CategoryGridColumn';

const CategoryGridLayout = ({ categories }) => {
  let newCategories = categories.slice();
  const getItemsInRow = (num) => newCategories.splice(0, num);

  return (
    <div className='category-listing-items'>
      <CategoryGridColumn>
        <CategoryGridRow categories={getItemsInRow(1)} />
      </CategoryGridColumn>
      {newCategories.length > 0 && (
        <CategoryGridColumn>
          <CategoryGridRow categories={getItemsInRow(1)} />
        </CategoryGridColumn>
      )}
      {newCategories.length > 0 && (
        <CategoryGridColumn>
          <CategoryGridRow categories={getItemsInRow(newCategories.length % 4 === 0 ? 2 : 1)} />
          {newCategories.length > 0 && <CategoryGridRow categories={newCategories} />}
        </CategoryGridColumn>
      )}
    </div>
  );
};
export default CategoryGridLayout;

CategoryGridLayout.propTypes = {
  categories: PropTypes.array.isRequired,
};
