import { createContext } from 'react';

const AppSelectorContext = createContext({
  answers: {},
  isResult: false,
  isAdmin: false,
  conditions: [],
  questions: [],
  questionPath: [],
  updateGlobalState: null,
  catalog: {
    categories: [],
    products: [],
  },
  showResults: false,
});

export default AppSelectorContext;
