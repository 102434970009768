import PropTypes from 'prop-types';
import Modal from './Modal';
import ModalOpener from './ModalOpener';
import useModal from '../../hooks/useModal';

const GenericModal = ({ className, children, openButton, modalClass }) => {
  const { closeModal, isVisible, openModal } = useModal();
  return (
    <div className={`${className} modal-wrapper`}>
      <ModalOpener handleClick={openModal}>{openButton}</ModalOpener>
      <Modal className={modalClass} isVisible={isVisible} closeModal={closeModal}>
        {children}
      </Modal>
    </div>
  );
};

export default GenericModal;

GenericModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
  openButton: PropTypes.element.isRequired,
  modalClass: PropTypes.string,
};

GenericModal.defaultProps = {
  className: '',
  modalClass: '',
};
