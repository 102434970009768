import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import ModalOpener from './ModalOpener';
import useModal from '../../hooks/useModal';

const VideoModal = ({ children, isOffline, video }) => {
  const { closeModal, isVisible, openModal } = useModal();

  const getVideo = () =>
    isOffline ? (
      <video autoPlay controls='controls' className='offline-video'>
        <source type='video/mp4' src={`videos/${video.type}/${video.offline}`} />
      </video>
    ) : (
      <iframe
        className='online-video'
        width='630'
        height='354'
        src={`https://videos.sproutvideo.com/embed/${video.online}?autoPlay=true&playerColor=0995e6`}
        frameBorder='0'
        allow='autoplay; fullscreen'
      />
    );

  return (
    <Fragment>
      <ModalOpener handleClick={openModal}>{children}</ModalOpener>
      <Modal isVisible={isVisible} closeModal={closeModal}>
        <div className='video-responsive'>{getVideo()}</div>
      </Modal>
    </Fragment>
  );
};
export default VideoModal;

VideoModal.propTypes = {
  children: PropTypes.node.isRequired,
  isOffline: PropTypes.bool.isRequired,
  video: PropTypes.object.isRequired,
};
