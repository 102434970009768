import PropTypes from 'prop-types';
import CategoryItem from '../../catalog/CategoryItem';

const CategoryGridRow = ({ categories, getRemainder }) => {
  const itemsInRow = categories.length;
  const remainderClass = itemsInRow % 3 === 0 ? 'remaining3' : 'remaining4';
  const itemsClass = itemsInRow === 1 ? 'one' : '';
  const className = getRemainder ? `${itemsClass} ${remainderClass}` : itemsClass;

  return (
    <div className={`category-row ${className}`}>
      {categories.map((category) => (
        <CategoryItem key={category.category_id} category={category} />
      ))}
    </div>
  );
};
export default CategoryGridRow;

CategoryGridRow.propTypes = {
  categories: PropTypes.array.isRequired,
  getRemainder: PropTypes.bool,
};
