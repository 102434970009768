import { useContext } from 'react';
import Tooltip from '../../components/tooltip/Tooltip';
import TooltipContent from '../../components/tooltip/TooltipContent';
import TooltipOpener from '../../components/tooltip/TooltipOpener';
import AppContext from '../../contexts/app/app-context';
import { hasOwnProp } from '../../utilities/generic';

const DownloadInstructions = () => {
  const context = useContext(AppContext);
  const { company } = context.state;
  const zipFile = `${company.name}_IPG.zip`;
  const zipFolder = `${company.name}_IPG`;
  const startFile = `Click_To_Start_The_${company.name.toUpperCase()}_IPG.html`;
  const instructions = [
    {
      text: `Click "The Download IPG v${context.DETAILS.version}" button.`,
    },
    {
      text: 'Select a location on your desktop to save the .zip file.',
    },
    {
      text: `Wait for the download to complete then locate the file "${zipFile}".`,
    },
    {
      text: `Right click the file "${zipFile}" and select "Extract All", then click Extract`,
      tooltip: 'imgs/instructions/ipg-instructions-extract.gif',
    },
    {
      text: `Once extracted, open the ${zipFolder} folder.`,
    },
    {
      text: `Double click the icon named "${startFile}".`,
      tooltip: 'imgs/instructions/ipg-instructions-play.gif',
    },
  ];

  return (
    <div className='instructions'>
      <div className='heading'>Instructions:</div>
      {instructions.map((item, i) => {
        return (
          <div key={i} className='instruction'>
            <div className={`content ${hasOwnProp(item, 'tooltip') && item.tooltip.length > 0 ? 'has-tooltip' : ''}`}>
              <span className='number'>{i + 1}.</span>
              <span className='text'>{item.text}</span>
              {hasOwnProp(item, 'tooltip') && item.tooltip.length > 0 && (
                <Tooltip>
                  <TooltipOpener />
                  <TooltipContent className='left-side vert-centered'>
                    <img src={item.tooltip} />
                  </TooltipContent>
                </Tooltip>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default DownloadInstructions;
