import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CustLink = ({ to, target = '', rel = '', children, ...rest }) => {
  const isExternal = to.substring(0, 4) === 'http';

  const getLink = () => (
    <Link to={to} {...rest}>
      {children}
    </Link>
  );
  const getAnchor = () => (
    <a href={to} rel={rel || ''} target={target || '_self'} {...rest}>
      {children}
    </a>
  );

  return isExternal ? getAnchor() : getLink();
};

export default CustLink;

CustLink.propTypes = {
  children: PropTypes.any,
  rel: PropTypes.string,
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
  to: PropTypes.string.isRequired,
};
