import { useContext } from 'react';
import PropTypes from 'prop-types';
import AppContext from '../contexts/app/app-context';

const CompanyLogo = ({ src, companyName }) => {
  const context = useContext(AppContext);
  const { isOffline } = context.state;
  const logoPath = '/imgs/company-logo'.substring(isOffline ? 1 : 0);

  return <img src={`${logoPath}/${src}`} alt={`${companyName} Logo`} title={`${companyName} Logo`} />;
};

export default CompanyLogo;

CompanyLogo.propTypes = {
  src: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
};
