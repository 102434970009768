import { StrictMode } from 'react';
import { render } from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { AppGlobalState } from './contexts/app/AppGlobalState';
import { CatalogState } from './contexts/catalog/CatalogState';
import { theme } from './components/styled/theme';
import Main from './components/Main';

const App = () => (
  <StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <AppGlobalState>
          <CatalogState>
            <Main />
          </CatalogState>
        </AppGlobalState>
      </ThemeProvider>
    </Router>
  </StrictMode>
);

render(<App />, document.getElementById('app'));
