import { animated, useSpringRef, useTransition } from '@react-spring/web';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { StyledMenuList } from '../../../components/styled/StyledMenu';
import AppContext from '../../../contexts/app/app-context';
import MenuLinks from './MenuLinks';

const MenuList = ({ updateAnimationChain }) => {
  const context = useContext(AppContext);
  const { menuVisible } = context.state;
  const menuTransRef = useSpringRef();

  // menu hide/show transition
  const transitions = useTransition(menuVisible, {
    ref: menuTransRef,
    // reverse: menuVisible,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 1 },
  });

  // add/remove animation reference on mount/unmount
  useEffect(() => {
    updateAnimationChain(menuTransRef);
    () => updateAnimationChain(menuTransRef, true);
    // eslint-disable-next-line
  }, []);

  return transitions(
    (styles, item) =>
      item && (
        <StyledMenuList as={animated.div} style={styles}>
          <ul>
            <MenuLinks updateAnimationChain={updateAnimationChain} />
          </ul>
        </StyledMenuList>
      )
  );
};

MenuList.propTypes = {
  updateAnimationChain: PropTypes.func.isRequired,
};

export default MenuList;
