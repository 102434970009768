import PropTypes from 'prop-types';
import { Form, Field, ErrorMessage } from 'formik';
import Message from '../../components/Message';
import LoadingButton from '../../components/LoadingButton';
import InputMask from 'react-input-mask';

const DownloadForm = ({ formActions }) => {
  const { isSubmitting, values } = formActions;
  const { message, messageType } = values;
  return (
    <Form>
      <Message message={message} type={messageType} show={!!messageType} />
      <div className='customer-info'>
        <div className='section-header'>
          <strong>Please complete this registration form to start your download.</strong>
        </div>
        <div className='form-row flex-row'>
          <div className='field required col col-full'>
            <label htmlFor='email'>
              <span>Email Address:</span>
            </label>
            <Field id='email' name='email' type='email' />
            <ErrorMessage name='email'>{(msg) => <div className='error-msg'>{msg}</div>}</ErrorMessage>
          </div>
        </div>
        <div className='form-row flex-row'>
          <div className='field required col col-span6'>
            <label htmlFor='firstName'>
              <span>First Name:</span>
            </label>
            <Field id='firstName' name='firstName' type='text' autoComplete='off' />
            <ErrorMessage name='firstName'>{(msg) => <div className='error-msg'>{msg}</div>}</ErrorMessage>
          </div>
          <div className='field required col col-span6'>
            <label htmlFor='lastName'>
              <span>Last Name:</span>
            </label>
            <Field id='lastName' name='lastName' type='text' autoComplete='off' />
            <ErrorMessage name='lastName'>{(msg) => <div className='error-msg'>{msg}</div>}</ErrorMessage>
          </div>
        </div>
        <div className='form-row flex-row'>
          <div className='field required col col-span6'>
            <label htmlFor='company'>
              <span>Company:</span>
            </label>
            <Field id='company' name='company' type='text' autoComplete='off' />
            <ErrorMessage name='company'>{(msg) => <div className='error-msg'>{msg}</div>}</ErrorMessage>
          </div>
          <div className='field required col col-span6'>
            <label htmlFor='phoneNumber'>
              <span>Phone Number:</span>
            </label>
            <Field name='phoneNumber'>
              {({ field }) => {
                return (
                  <InputMask
                    id='phoneNumber'
                    name='phoneNumber'
                    type='tel'
                    autoComplete='off'
                    mask='(999) 999-9999'
                    alwaysShowMask={true}
                    onChange={field.onChange}
                    value={field.value}
                  />
                );
              }}
            </Field>
            <ErrorMessage name='phoneNumber'>{(msg) => <div className='error-msg'>{msg}</div>}</ErrorMessage>
          </div>
        </div>
        <div className='form-row btn-row align-right'>
          <LoadingButton className='primary' name='login-button' type='submit' loading={isSubmitting}>
            <span>Submit</span>
          </LoadingButton>
        </div>
      </div>
    </Form>
  );
};

export default DownloadForm;

DownloadForm.propTypes = {
  formActions: PropTypes.shape({
    isSubmitting: PropTypes.bool.isRequired,
    values: PropTypes.shape({
      message: PropTypes.string.isRequired,
      messageType: PropTypes.string.isRequired,
    }),
  }),
};
