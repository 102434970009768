import PropTypes from 'prop-types';
import FeatureCallouts from './FeatureCallouts';
import OptionsList from './OptionsList';
import SwatchSelection from './swatches/SwatchSelection';
import ErrorMessage from '../../components/ErrorMessage';

const ContentPages = ({ content, ...rest }) => {
  const getContentPageTemplate = () => {
    switch (content.type) {
      case 'callout':
        return <FeatureCallouts content={content} {...rest} />;
      case 'list':
        return <OptionsList content={content} {...rest} />;
      case 'select':
        return <SwatchSelection content={content} {...rest} />;
      case 'content':
      default:
        return <ErrorMessage type='no-content' />;
    }
  };

  return <div className='content-page'>{getContentPageTemplate()}</div>;
};
export default ContentPages;

ContentPages.propTypes = {
  content: PropTypes.object.isRequired,
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    options: PropTypes.object,
  }),
};
