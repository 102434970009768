// export all action keywords
export const CAROUSEL_SLIDEINDEX = 'CAROUSEL_SLIDEINDEX';
export const QUOTELIST_ADD = 'QUOTELIST_ADD';
export const QUOTELIST_REMOVE = 'QUOTELIST_REMOVE';
export const QUOTELIST_CLEARALL = 'QUOTELIST_CLEARALL';
export const SET_CATALOG = 'SET_CATALOG';

const setCarouselSlideIndex = (state, { pathname, slideIndex }) => ({
  ...state,
  carouselIndex: { pathname, slideIndex },
});

const clearAllFromQuoteList = (state) => ({ ...state, quoteList: [] });

const addToQuoteList = (state, { id }) => {
  let newQuoteList = state.quoteList.slice();

  if (!newQuoteList.includes(id)) {
    newQuoteList = [...newQuoteList, id];
  }

  return { ...state, quoteList: newQuoteList };
};

const removeFromQuoteList = (state, { id }) => {
  const index = state.quoteList.indexOf(id);
  let newQuoteList = state.quoteList.slice();

  if (index >= 0) {
    newQuoteList = [...newQuoteList.slice(0, index), ...newQuoteList.slice(index + 1)];
  }

  return { ...state, quoteList: newQuoteList };
};

const setCatalog = (state, { categories, products }) => {
  const updatedCatlog = { ...state.catalog, categories, products };
  return { ...state, catalog: updatedCatlog };
};

export const catalogReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CATALOG:
      return setCatalog(state, payload);
    case CAROUSEL_SLIDEINDEX:
      return setCarouselSlideIndex(state, payload);
    case QUOTELIST_ADD:
      return addToQuoteList(state, payload);
    case QUOTELIST_REMOVE:
      return removeFromQuoteList(state, payload);
    case QUOTELIST_CLEARALL:
      return clearAllFromQuoteList(state);
    default:
      return state;
  }
};
