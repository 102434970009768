import PropTypes from 'prop-types';
import Header from './Header';
import BackButton from './BackButton';
import Breadcrumbs from '../../components/Breadcrumbs';

const PageWrapper = ({ children, isErrorPage }) => (
  <div className='page'>
    <Header />
    <Breadcrumbs isErrorPage={isErrorPage} />
    {children}
    <BackButton />
  </div>
);

export default PageWrapper;

PageWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  isErrorPage: PropTypes.bool,
};

PageWrapper.defaultProps = {
  isErrorPage: false,
};
