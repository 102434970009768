import { cloneElement } from 'react';
import PropTypes from 'prop-types';

const ModalOpener = ({ children, handleClick }) => {
  return cloneElement(children, {
    onClick: handleClick,
  });
};
export default ModalOpener;

ModalOpener.displayName = 'ModalOpener';

ModalOpener.propTypes = {
  children: PropTypes.element.isRequired,
  handleClick: PropTypes.func.isRequired,
};

ModalOpener.defaultProps = {
  handleClick: () => console.log('click event needed!'),
};
