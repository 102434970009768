import PropTypes from 'prop-types';

const TooltipOpener = ({ children, className = '' }) => {
  const expr = /ico-(before|after)/gi;
  const classes = className.search(expr) !== -1 ? className : `${className} ico-before`;

  return <div className={`tooltip-opener ${classes}`}>{children}</div>;
};
export default TooltipOpener;

TooltipOpener.displayName = 'TooltipOpener';

TooltipOpener.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
