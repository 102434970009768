import PropTypes from 'prop-types';

const DownloadFormSuccess = ({ downloadLink, company, version }) => (
  <div>
    <p>
      <strong>Thank you for your information. Please use the download link below to get your copy of the IPG!</strong>
    </p>
    <p style={{ textAlign: 'center' }}>
      <a href={`//${downloadLink}`} target='_blank' rel='noreferrer'>
        Download the {company} IPG v{version}
      </a>
    </p>
    <p style={{ fontSize: '13px', lineHeight: '20px' }}>
      ** Important ** - Please wait for the whole download to finish before closing your browser. Also, be sure to right
      click on the file zip file and choose &quot;Extract All...&quot; and then click the Extract button.
    </p>
  </div>
);

export default DownloadFormSuccess;

DownloadFormSuccess.propTypes = {
  downloadLink: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};
