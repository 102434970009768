import styled from 'styled-components';

export const StyledSystemReqOpener = styled.button`
  background: transparent;
  border: none;
  color: #787878;
  cursor: pointer;
  display: inline-block;
  margin: 0.5rem 0;
  padding: 0;
  &:before {
    content: '\f05a';
    font-family: 'FontAwesome';
    font-size: 1.1rem;
    margin-right: 0.4rem;
    vertical-align: middle;
  }
  &:hover {
    span {
      text-decoration: underline;
    }
  }
  span {
    font-size: 1.3rem;
    line-height: 1.1;
    vertical-align: middle;
  }
`;

export const StyledDownloadButton = styled.button`
  background-color: #ffffff;
  border: 0.3rem solid ${({ theme }) => theme.primary};
  border-radius: 1rem;
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 2px 16px rgba(0, 0, 0, 0.2);
  color: ${({ theme }) => theme.primary};
  cursor: pointer;
  display: inline-block;
  float: none;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0.5rem 0;
  padding: 0.75rem 1.2rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1) all;
  span {
    letter-spacing: 0.07rem;
    line-height: 1.1;
    position: relative;
    vertical-align: middle;
    z-index: 2;
    &:before {
      content: '\f0ab';
      font-family: 'FontAwesome';
      font-size: 1.4rem;
      margin-right: 0.4rem;
      vertical-align: middle;
    }
  }
  &:before {
    opacity: 0;
    content: '';
    background-color: ${({ theme }) => theme.primary};
    border-radius: 0.5rem;
    top: -1%;
    left: -1%;
    width: 102%;
    height: 102%;
    position: absolute;
    transform: scaleX(0);
    transition: 0.5s cubic-bezier(0.88, 0.01, 0.16, 0.99) all;
    z-index: 1;
  }
  &:hover {
    color: #ffffff;
    span {
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    }
    &:before {
      opacity: 1;
      transform: scaleX(1);
    }
  }
`;
