import { useContext } from 'react';
import PropTypes from 'prop-types';
import Image from '../../components/Image';
import VideoModal from '../../components/modal/VideoModal';
import AppContext from '../../contexts/app/app-context';
import {
  StyledProductVideoButton,
  StyledProductVideoList,
  StyledProductVideoListItem,
  StyledProductVideoTitle,
} from '../../components/styled/product/StyledProductVideo';
import { StyledTitleH1 } from '../../components/styled/StyledTitle';

const ProductVideos = ({ product, videos, fallbackImage = null }) => {
  const context = useContext(AppContext);
  const { isOffline } = context.state;
  const { name, image } = product;

  const fallback = fallbackImage ? `imgs/${fallbackImage}` : null;
  const imgsrc = image !== null ? `imgs/products/${image}` : fallback;

  return (
    <div className='content-page'>
      <StyledTitleH1>{name} Videos</StyledTitleH1>
      <div className='product-section videos'>
        <div className='left-col'>
          <div className='product-image'>
            <Image src={imgsrc} width={600} height={600} />
          </div>
        </div>
        <div className='right-col'>
          <StyledProductVideoList>
            {videos.map((video) => {
              return (
                <StyledProductVideoListItem key={video.video_id}>
                  <StyledProductVideoTitle>{video.name}</StyledProductVideoTitle>
                  <VideoModal video={video} isOffline={isOffline}>
                    <StyledProductVideoButton>
                      <span>Play Video</span>
                    </StyledProductVideoButton>
                  </VideoModal>
                </StyledProductVideoListItem>
              );
            })}
          </StyledProductVideoList>
        </div>
      </div>
    </div>
  );
};
export default ProductVideos;

ProductVideos.propTypes = {
  videos: PropTypes.array.isRequired,
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }),
  fallbackImage: PropTypes.string,
};
