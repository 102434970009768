import { animated, useSpringRef, useTransition } from '@react-spring/web';
import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo } from 'react';
import CustLink from '../../../components/CustLink';
import AppContext from '../../../contexts/app/app-context';
import { TOGGLE_VISIBLE } from '../../../contexts/app/app-reducer';
import CatalogContext from '../../../contexts/catalog/catalog-context';

const MenuLinks = ({ updateAnimationChain }) => {
  const context = useContext(AppContext);
  const { enableApplications, enableQuoting, enableDownload, isOffline, menuVisible } = context.state;

  const catalogContext = useContext(CatalogContext);
  const { quoteList } = catalogContext.state;
  const mylistCount = quoteList.length > 0 ? `(${quoteList.length})` : '';

  const links = useMemo(() => {
    const links = [
      { class: 'home-link', dest: '/c', name: 'Home', visible: true },
      { class: 'download', dest: '/download', name: 'Download the IPG', visible: enableDownload && !isOffline },
      {
        class: 'my-list',
        dest: '/my-list',
        name: `View My List ${mylistCount}`,
        visible: enableQuoting && !isOffline,
      },
      {
        class: 'app-selector',
        dest: '/application-selector',
        name: 'Application Selector',
        sup: 'beta',
        visible: enableApplications,
      },
    ];
    return links.filter((link) => link.visible === true);
  }, [isOffline, mylistCount, enableApplications, enableQuoting, enableDownload]);

  const menuLinksRef = useSpringRef();
  const transitions = useTransition(menuVisible ? links : [], {
    keys: (link) => link.dest,
    ref: menuLinksRef,
    // reverse: menuVisible,
    trail: 400 / links.length,
    from: { opacity: 0, x: 1 },
    enter: { opacity: 1, x: 0 },
    leave: { opacity: 0.1, x: 1 },
    config: { tension: 400, friction: 40 },
  });

  const slideInOut = (x) => {
    const newX = menuVisible ? `${x * -20}px` : `${x * 100}%`;
    return `translate3d(${newX},0,0`;
  };

  const toggleVisibile = () => context.dispatch({ type: TOGGLE_VISIBLE });

  // add/remove animation reference on mount/unmount
  useEffect(() => {
    updateAnimationChain(menuLinksRef);
    () => updateAnimationChain(menuLinksRef, true);
    // eslint-disable-next-line
  }, []);

  return transitions((styles, item) => (
    <animated.li
      key={item.key}
      className={item.class}
      style={{
        opacity: styles.opacity,
        transform: styles.x.to(slideInOut),
        transformOrigin: styles.transformOrigin,
      }}
    >
      <CustLink onClick={toggleVisibile} to={item.dest} target={item.target || '_self'}>
        <span>{item.name}</span> {item.sup && <sup>{item.sup}</sup>}
      </CustLink>
    </animated.li>
  ));
};

MenuLinks.propTypes = {
  updateAnimationChain: PropTypes.func.isRequired,
};

export default MenuLinks;

/*
{
  class: "app-selector",
  dest: "https://spiralbinding.com/pgs/application-selection.cfm",
  target: "_blank",
  rel: "noopener noreferrer",
  name: "Application Selector",
  visible: enableApplications,
},
*/
