import { useChain } from '@react-spring/web';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CompanyLogo from '../../components/CompanyLogo';
import { StyledMyListItem } from '../../components/styled/StyledMenu';
import AppContext from '../../contexts/app/app-context';
import CatalogContext from '../../contexts/catalog/catalog-context';
import MenuButton from './menu/MenuButton';
import MenuList from './menu/MenuList';

const Header = () => {
  const context = useContext(AppContext);
  const { company, enableQuoting, isOffline, menuVisible } = context.state;

  const catalogContext = useContext(CatalogContext);
  const { quoteList } = catalogContext.state;
  const newItemsCount = quoteList.length;

  // states for bounce effect
  const [bounce, setBounce] = useState('');
  const [itemsCount, setItemsCount] = useState(newItemsCount);

  // animation chain and references
  const [animationChain, setAnimationChain] = useState([]);
  const updateAnimationChain = useCallback(
    (ref, remove = false) => {
      const index = animationChain.indexOf(ref);
      if (index < 0) {
        return setAnimationChain((v) => [...v, ref]);
      }

      if (index >= 0 && remove) {
        return setAnimationChain((v) => [...v.slice(0, index), ...v.slice(index + 1)]);
      }

      return null;
    },
    [animationChain]
  );
  useChain(menuVisible ? animationChain : animationChain.slice().reverse());

  useEffect(() => {
    if (itemsCount != newItemsCount) {
      setItemsCount(newItemsCount);
      setBounce(itemsCount < newItemsCount ? 'bounce-in' : 'bounce-out');
    }
  }, [itemsCount, newItemsCount]);

  return (
    <Fragment>
      <header>
        <div className='logo'>
          <Link index='0' to='/c'>
            <CompanyLogo src={company.logo} companyName={company.name} />
          </Link>
        </div>
        {enableQuoting && !isOffline && (
          <StyledMyListItem className={`mylist-items ${bounce}`}>
            <span>{itemsCount}</span>
          </StyledMyListItem>
        )}
        <div className='menu'>
          <MenuButton updateAnimationChain={updateAnimationChain} />
          <MenuList updateAnimationChain={updateAnimationChain} />
        </div>
      </header>
    </Fragment>
  );
};
export default Header;
