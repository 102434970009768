import { useContext } from 'react';
import PropTypes from 'prop-types';
import Image from '../../components/Image';
import ProductSpecs from './ProductSpecs';
import { getRawMarkup } from '../../utilities/generic';
import ProductQuoteButton from './ProductQuoteButton';
import AppContext from '../../contexts/app/app-context';
import {
  StyledProductDescription,
  StyledProductDetails,
  StyledProductHeading,
  StyledProductName,
  StyledProductSku,
  StyledProductSpecWrapper,
} from '../../components/styled/product/StyledProductInfo';

const ProductFeatures = ({ product }) => {
  const context = useContext(AppContext);
  const { enableQuoting, isOffline } = context.state;

  const productImage = product.alt_image ? product.alt_image : product.image;

  return (
    <div className='product-section features'>
      <div className='left-col'>
        <div className='product-image'>
          <Image src={`imgs/products/${productImage}`} width={600} height={600} />
          {enableQuoting && !isOffline && <ProductQuoteButton product={product} />}
        </div>
      </div>
      <div className='right-col'>
        <StyledProductDetails>
          <StyledProductHeading>
            <StyledProductName>{product.name}</StyledProductName>
            {product.sku && <StyledProductSku>{product.sku}</StyledProductSku>}
          </StyledProductHeading>
          {typeof product.desc !== 'undefined' && (
            <StyledProductDescription dangerouslySetInnerHTML={getRawMarkup(product.desc)} />
          )}
        </StyledProductDetails>
        {typeof product.specs !== 'undefined' && (
          <StyledProductSpecWrapper>
            <ProductSpecs specs={product.specs} />
          </StyledProductSpecWrapper>
        )}
      </div>
    </div>
  );
};
export default ProductFeatures;

ProductFeatures.propTypes = {
  product: PropTypes.object.isRequired,
};
