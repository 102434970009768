import styled from 'styled-components';
import { darken } from 'polished';

export const StyledTabBarTabItem = styled.li`
  background-color: ${({ isActive, theme }) => (isActive ? theme.primary : '#bababa')};
  border-left: 0.1rem solid ${({ isActive, theme }) => (isActive ? darken(0.1, theme.primary) : '#787878')};
  border-bottom: 0.25rem solid ${({ isActive, theme }) => (isActive ? darken(0.1, theme.primary) : '#787878')};
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.8rem;
  text-align: center;
  text-transform: capitalize;
  span {
    margin: auto;
  }
  ${({ isActive, theme }) =>
    isActive &&
    `+ ${StyledTabBarTabItem} {
			border-left-color: ${darken(0.1, theme.primary)};
	}`}
  &:hover {
    opacity: 0.8;
  }
  &:first-child {
    border-radius: 0.5rem 0 0 0.5rem;
    border-left: none;
  }
  &:last-child {
    border-radius: 0 0.5rem 0.5rem 0;
    border-right: none;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mobile_rg}) {
    font-size: 0.9rem;
    flex-shrink: 1;
    min-height: 60px;
    padding: 0.6rem;
    // fix for IE11
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      font-size: 1.1rem;
      padding: 0.7rem;
    }
  }
  // fix for IE11
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    min-width: 1px;
    margin-top: auto;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
  }
`;
