import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import PageWrapper from './global/PageWrapper';
import { AppSelectorForm } from '../forms';
import { AppSelectorState, AppSelectorContext } from '../contexts/appselector';
import { hasOwnProp } from '../utilities/generic';
import AppContext from '../contexts/app/app-context';
import { StyledResetOutlineButton } from '../components/styled/StyledActionBar';

const AppSelectorTitleBar = ({ init }) => {
  const app = useContext(AppContext);
  const context = useContext(AppSelectorContext);

  const resetAppSelector = () => {
    context.actions.updateValue('questionPath', [`${init}`]); // set question path
    context.actions.updateValue('answers', {}); // set question answers
    context.actions.updateValue('isResult', false); // set to true if there's no next value

    const updatedState = { answers: {}, questionPath: [`${init}`], isResult: false };
    app.actions.updateValue('appSelector', { ...app.state.appSelector, ...updatedState });
  };

  return (
    <div className='page-title-bar'>
      <h1>
        Application Selector <sup style={{ fontSize: '1rem' }}>beta</sup>
      </h1>
      <div className='action-bar main'>
        <div className='group-btns right'>
          <StyledResetOutlineButton onClick={resetAppSelector}>
            <span>Reset Selections</span>
          </StyledResetOutlineButton>
        </div>
      </div>
    </div>
  );
};

AppSelectorTitleBar.propTypes = {
  init: PropTypes.string.isRequired,
};

const AppSelector = () => {
  const params = useParams();
  const app = useContext(AppContext);
  const { isOffline } = app.state;
  const { answers, isResult, questionPath } = app.state.appSelector;
  const { init, questions } = window.applications;

  useEffect(() => {
    // get answers either from the app state or the URL path param if either exists
    const { answers: urlAnswers } = params;
    const urlParamAnswers = urlAnswers !== undefined ? JSON.parse(atob(decodeURIComponent(urlAnswers))) : {};
    const preloadedAnswers = Object.keys(answers).length > 0 ? answers : urlParamAnswers;

    // get question path or start witht he inital path
    const questionPath = [];
    Object.keys(preloadedAnswers).map((question) => questionPath.push(question.replace(/\D+/g, '')));
    const qid = questionPath.slice(-1)[0];
    const lastQuestion = qid ? questions.find((question) => question.question_id === qid) : null;
    const answer = lastQuestion
      ? lastQuestion.answers.find((answer) => answer.answer_id === preloadedAnswers[`question${qid}`])
      : false;
    const isResult = answer && !(hasOwnProp(answer, 'next') && answer.next !== null);

    // push the next question if it's not a finished result path
    if (!isResult && questionPath.length > 0) {
      questionPath.push(answer.next);
    }

    // set information to app state
    app.actions.updateValue('appSelector.answers', preloadedAnswers);
    app.actions.updateValue('appSelector.questionPath', questionPath.length > 0 ? questionPath : [`${init}`]);
    app.actions.updateValue('appSelector.isResult', isResult);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateGlobalState = (appSelectorState) => {
    const newAppSelectorState = { ...app.state.appSelector, ...appSelectorState };
    app.actions.updateValue('appSelector', newAppSelectorState);
  };

  const initialValues = {
    answers: answers,
    hideShare: isOffline,
    isAdmin: false,
    isResult: isResult,
    init: init,
    questions: questions,
    questionPath: questionPath.length > 0 ? questionPath : [`${init}`],
    showResults: isResult,
    updateGlobalState: updateGlobalState,
  };

  return (
    <PageWrapper>
      <div className='app-selector page-wrapper'>
        <AppSelectorState initialValues={initialValues}>
          <AppSelectorTitleBar init={init} />
          <AppSelectorForm />
        </AppSelectorState>
      </div>
    </PageWrapper>
  );
};

export default AppSelector;
