import PropTypes from 'prop-types';

const ErrorMessage = ({ type, title = null, classNames = '' }) => {
  const getMessageByType = () => {
    switch (type) {
      case 'no-items':
        return "We're sorry, their are currently no items in this category, please check back later for updates.";
      case 'no-app-product':
        return 'No products to display based on your selection.';
      case 'product-error':
        return "We're sorry, their seems to be an issue with this product, please check back later for updates.";
      case 'no-content':
        return "We're sorry, this content has not been added yet, please check back later for updates.";
      case 'not-found':
      case 'nonexistent-page':
        return "We're sorry, but the page you are trying to visit does not exist. Please check the URL and try again.";
      case 'unavailable-offline':
        return "We're sorry, but the page you are trying to access is not available in the downloaded version of the IPG.";
      default:
        return "Sorry, it looks like we've encountered an error, please check back later for updates.";
    }
  };

  return (
    <div>
      {title && <h1>{title}</h1>}
      <p className={`page-error-msg ${classNames}`}>{getMessageByType()}</p>
    </div>
  );
};
export default ErrorMessage;

ErrorMessage.propTypes = {
  classNames: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};
