import { Fragment, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CatalogContext from '../../contexts/catalog/catalog-context';
import { QUOTELIST_ADD, QUOTELIST_REMOVE } from '../../contexts/catalog/catalog-reducer';
import {
  StyledAddedQuoteButton,
  StyledQuoteButton,
  StyledQuoteButtons,
  StyledRemoveQuoteButton,
} from '../../components/styled/product/StyledProductQuoteButton';

const ProductQuoteButton = ({ product }) => {
  const context = useContext(CatalogContext);
  const { quoteList } = context.state;

  const inQuoteList = useMemo(() => quoteList.indexOf(product.product_id) > -1, [quoteList, product]);
  const handleClick = () => {
    const type = inQuoteList ? QUOTELIST_REMOVE : QUOTELIST_ADD;
    context.dispatch({
      type: type,
      payload: { id: product.product_id },
    });
  };

  return (
    <StyledQuoteButtons>
      {inQuoteList ? (
        <Fragment>
          <StyledAddedQuoteButton as={Link} to='/my-list'>
            <span>Go To List</span>
          </StyledAddedQuoteButton>
          <StyledRemoveQuoteButton onClick={handleClick}>
            <span>Remove</span>
          </StyledRemoveQuoteButton>
        </Fragment>
      ) : (
        <StyledQuoteButton onClick={handleClick}>
          <span>Add To List</span>
        </StyledQuoteButton>
      )}
    </StyledQuoteButtons>
  );
};

export default ProductQuoteButton;

ProductQuoteButton.propTypes = {
  product: PropTypes.object.isRequired,
};
