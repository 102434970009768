import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ErrorPage from './ErrorPage';
import PageWrapper from './global/PageWrapper';
import ContentPages from './content/ContentPages';
import ProductFeatures from './product/ProductFeatures';
import Tabs from '../components/tabs/Tabs';
import TabPanel from '../components/tabs/TabPanel';
import CatalogContext from '../contexts/catalog/catalog-context';
import ProductVideos from './product/ProductVideos';
import AskTheExpert from './product/AskTheExpert';

const ProductPage = () => {
  const location = useLocation();
  const context = useContext(CatalogContext);
  const [product, setProduct] = useState(null);
  const videos = useMemo(() => product?.videos || [], [product]);

  useEffect(() => {
    setProduct(context.actions.getCurrentProduct(location.pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const infoVideos = useMemo(() => {
    return videos ? videos.filter((vid) => vid.type === 'product') : [];
  }, [videos]);
  const faqVideos = useMemo(() => {
    return videos ? videos.filter((vid) => vid.type === 'faq') : [];
  }, [videos]);

  if (!product) {
    return <ErrorPage type='product-error' title={(product && product.name) || ''} />;
  }

  return (
    <PageWrapper>
      <div className='product page-wrapper'>
        <Tabs className={'product'}>
          <TabPanel label={'features'} tabid={`product_features_${product.product_id}`}>
            <ProductFeatures product={product} />
          </TabPanel>
          {typeof product.contentPages !== 'undefined' &&
            product.contentPages.map((content) => (
              <TabPanel
                label={content.title}
                tabid={`content_${content.content_page_id}`}
                key={`content_${content.content_page_id}`}
              >
                <ContentPages content={content} fallbackImage={`products/${product.image}`} product={product} />
              </TabPanel>
            ))}
          {faqVideos.length > 0 && (
            <TabPanel label={'ask the expert'} tabid={`product_videos_${product.product_id}`}>
              <AskTheExpert product={product} videos={faqVideos} />
            </TabPanel>
          )}
          {infoVideos.length > 0 && (
            <TabPanel label={'videos'} tabid={`product_videos_${product.product_id}`}>
              <ProductVideos product={product} videos={infoVideos} fallbackImage={`products/${product.image}`} />
            </TabPanel>
          )}
        </Tabs>
      </div>
    </PageWrapper>
  );
};
export default ProductPage;

ProductPage.propTypes = {};
