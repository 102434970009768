import { animated, useSpringRef, useTransition } from '@react-spring/web';
import PropTypes from 'prop-types';
import { Fragment, useContext, useEffect } from 'react';
import { StyledMenuButton, StyledMenuLine, StyledMenuOverlay } from '../../../components/styled/StyledMenu';
import AppContext from '../../../contexts/app/app-context';
import { TOGGLE_VISIBLE } from '../../../contexts/app/app-reducer';

const MenuButton = ({ updateAnimationChain }) => {
  const context = useContext(AppContext);
  const { menuVisible } = context.state;
  const menuBgRef = useSpringRef();

  const toggleVisibile = () => context.dispatch({ type: TOGGLE_VISIBLE });

  const borderRadius = (r) => {
    const half = r * 0.5 * 100;
    const quarter = r * 0.25 * 100;
    return `${half}% ${quarter}% ${half}% ${half}%`;
  };

  // menu background grow/shrink transition
  const transition = useTransition(menuVisible, {
    ref: menuBgRef,
    // reverse: menuVisible,
    from: { scale: 0, rad: 1 },
    enter: { scale: 1, rad: 0 },
    leave: { scale: 0, rad: 0.5 },
    config: { duration: 200 },
  });

  // add/remove animation reference on mount/unmount
  useEffect(() => {
    updateAnimationChain(menuBgRef);
    () => updateAnimationChain(menuBgRef, true);
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <StyledMenuButton onClick={toggleVisibile} isActive={menuVisible}>
        <StyledMenuLine className='menu-line' isActive={menuVisible} />
      </StyledMenuButton>
      {transition(
        (styles, item) =>
          item && (
            <StyledMenuOverlay
              as={animated.div}
              style={{
                borderRadius: styles.rad.to(borderRadius),
                transform: styles.scale.to((s) => `scale(${s},${s})`),
              }}
            />
          )
      )}
    </Fragment>
  );
};

MenuButton.propTypes = {
  updateAnimationChain: PropTypes.func.isRequired,
};

export default MenuButton;
