import { useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AppContext from '../../contexts/app/app-context';
import DownloadForm from './DownloadForm';
import DownloadFormSuccess from './DownloadFormSuccess';

const DownloadFormWrapper = ({ downloadLink, ipgCompany, version }) => {
  const app = useContext(AppContext);
  const { apiUrl } = app.state;

  const initialValues = {
    company: '',
    email: '',
    firstName: '',
    lastName: '',
    message: '',
    messageType: '',
    phoneNumber: '',
    showSuccess: false,
    wufooHash: 'm1s354df0bkecm7',
    downloadLink: downloadLink,
    ipgCompany: ipgCompany,
    version: version,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required.'),
    lastName: Yup.string().required('Last name is required.'),
    email: Yup.string().email('Please enter a valid email address.').required('Email address is required'),
    company: Yup.string().required('Company name is required'),
    phoneNumber: Yup.string()
      .matches(/^\(\d{3}\) \d{3}-\d{4}/, {
        message: 'Phone number is not in a valid format',
        excludeEmptyString: true,
      })
      .required('Phone number is required'),
  });

  const formSubmitError = (res, values, setValues) => {
    try {
      const message = res.data.message || res.request.responseText;
      setValues({ ...values, message, messageType: 'error' });
    } catch (err) {
      console.error(err);
    }
  };

  const formSubmitSuccess = (res, values, resetForm, setValues) => {
    try {
      const { messageType = 'error' } = res.data;
      if (messageType !== 'success') {
        return formSubmitError(res, values, setValues);
      }
      setValues({ ...values, message: '', messageType: '', showSuccess: true });
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmit = (values, { resetForm, setSubmitting, setValues }) => {
    const fieldKeys = [
      'wufooHash',
      'firstName',
      'lastName',
      'email',
      'company',
      'phoneNumber',
      'downloadLink',
      'ipgCompany',
      'version',
    ];

    // get only neccessary data to post
    const formData = fieldKeys.reduce((obj, key) => ({ ...obj, [key]: values[key] }), {});

    // post to WuFoo
    axios
      .post(`${apiUrl}/download/ajax/submit-post`, formData, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((response) => formSubmitSuccess(response, values, resetForm, setValues))
      .catch(({ response }) => formSubmitError(response, values, setValues))
      .then(() => setSubmitting(false));
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(formActions) =>
        formActions.values.showSuccess === false ? (
          <DownloadForm formActions={formActions} />
        ) : (
          <DownloadFormSuccess downloadLink={downloadLink} company={ipgCompany} version={version} />
        )
      }
    </Formik>
  );
};

export default DownloadFormWrapper;

DownloadFormWrapper.propTypes = {
  downloadLink: PropTypes.string.isRequired,
  ipgCompany: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};
