import { useContext, useMemo } from 'react';
import AppSelectorOptionalResults from './AppSelectorOptionalResults';
import { AppSelectorContext } from '../../../contexts/appselector';
import ModalOpener from '../../../components/modal/ModalOpener';
import Modal from '../../../components/modal/Modal';
import { useModal } from '../../../hooks';
import { hasOwnProp } from '../../../utilities/generic';
import ProductAndCategoryCarousel from '../../../components/ProductAndCategoryCarousel';
import { StyledShareOutlineButton } from '../../../components/styled/StyledActionBar';

const ApplicationSelectorResults = () => {
  const { closeModal, isVisible, openModal } = useModal();
  const context = useContext(AppSelectorContext);
  const { answers, hideShare, isAdmin, questionPath, questions } = context.state;
  const question = questions.find((question) => question.question_id === questionPath.slice(-1)[0]);
  const answer = question.answers.find((answer) => answer.answer_id === answers[`question${question.question_id}`]);

  const hashLink = useMemo(() => {
    const pathHash = encodeURIComponent(btoa(JSON.stringify(answers)));
    const baseURL = `${window.location.protocol}//${window.location.hostname}`;
    return `${baseURL}/#/application-selector/${pathHash}`;
  }, [answers]);

  const selectTextAndCopy = (ev) => {
    const button = ev.currentTarget;
    const buttonText = button.innerHTML;
    const input = document.getElementById('shareLink');

    // focus and select input, copy the selection, and remove the selection
    input.focus();
    input.select();
    document.execCommand('copy');
    input.blur();

    if (window.getSelection) {
      window.getSelection().removeAllRanges(); // All browsers, except IE <=8
    } else if (document.selection) {
      document.selection.empty(); // IE <=8
    }

    // change the button text to copied for 1 second to give a brief visual confirmation to the user
    button.innerHTML = 'Copied!';
    setTimeout(() => (button.innerHTML = buttonText), 1000);
  };

  const hasOptionalResult = () => {
    if (!hasOwnProp(answer, 'optional')) {
      return false;
    }

    const optional = { products: [], cateogries: [], ...answer.optional };
    return optional.products.length > 0 || optional.categories.length > 0;
  };

  // fail safe to keep from errors
  if (!answer || !answer.products) {
    return '';
  }

  return (
    <div className='results'>
      <div className='recommended'>
        <p className='title'>Recommended Products:</p>
        {!hideShare && (
          <div className='share-section'>
            <div className='share-section-wrapper'>
              <div className='action-bar'>
                <div className='group-btns right'>
                  <ModalOpener handleClick={openModal}>
                    <StyledShareOutlineButton>
                      <span>Share</span>
                    </StyledShareOutlineButton>
                  </ModalOpener>
                </div>
              </div>
              <Modal
                className='share-section-modal'
                modalClassName='max600'
                isVisible={isVisible}
                closeModal={closeModal}
              >
                <div className='share-body-wrapper'>
                  <label>
                    <p className='title'>
                      <strong>Share This Application Result</strong>
                    </p>
                  </label>
                  <input type='text' id='shareLink' readOnly value={hashLink} />
                  <div className='button-row'>
                    <button className='btn primary copy' onClick={selectTextAndCopy}>
                      <span>Copy URL</span>
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        )}
        <div className='result-items'>
          <ProductAndCategoryCarousel productIds={answer.products} rememberSliderPosition={!isAdmin} />
        </div>
      </div>
      {hasOptionalResult() && <AppSelectorOptionalResults results={answer.optional} />}
    </div>
  );
};

export default ApplicationSelectorResults;
