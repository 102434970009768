import styled from 'styled-components';

export const StyledTitleH1 = styled.h1`
  color: ${({ theme }) => theme.secondary};
  font-weight: 700;
`;

export const StyledTitleH1Category = styled(StyledTitleH1)`
  margin-left: 1.5rem;
  text-transform: uppercase;
`;
