import { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import AppSelectorContext from './appselector-context';
import { appSelectorReducer, SET_STATE, UPDATE_VALUE } from './appselector-reducer';

// default state values
const defaults = {
  answers: {},
  isResult: false,
  isAdmin: false,
  conditions: [],
  questions: [],
  questionPath: [],
  updateGlobalState: null,
  catalog: {
    categories: [],
    products: [],
  },
  showResults: false,
};

export const AppSelectorState = ({ children, initialValues = {} }) => {
  const [state, dispatch] = useReducer(appSelectorReducer, { ...defaults, ...initialValues });

  // allows to reset the intial state if new initial values are passed
  useEffect(() => {
    dispatch({
      type: SET_STATE,
      payload: { ...defaults, ...initialValues },
    });
  }, [initialValues]);

  const updateValue = (field, value) => {
    dispatch({
      type: UPDATE_VALUE,
      payload: { field, value },
    });
  };

  const actions = { updateValue };

  return <AppSelectorContext.Provider value={{ state, dispatch, actions }}>{children}</AppSelectorContext.Provider>;
};
AppSelectorState.propTypes = {
  initialValues: PropTypes.object,
  children: PropTypes.node.isRequired,
};
