import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import LoadingButton from '../../../components/LoadingButton';
import InputMask from 'react-input-mask';
import Select from '../../components/Select';

const states = [
  { id: 'AK', value: 'AK - Alaska' },
  { id: 'AL', value: 'AL - Alabama' },
  { id: 'AR', value: 'AR - Arkansas' },
  { id: 'AZ', value: 'AZ - Arizona' },
  { id: 'CA', value: 'CA - California' },
  { id: 'CO', value: 'CO - Colorado' },
  { id: 'CT', value: 'CT - Connecticut' },
  { id: 'DC', value: 'DC - District of Columbia' },
  { id: 'DE', value: 'DE - Delaware' },
  { id: 'FL', value: 'FL - Florida' },
  { id: 'GA', value: 'GA - Georgia' },
  { id: 'HI', value: 'HI - Hawaii' },
  { id: 'IA', value: 'IA - Iowa' },
  { id: 'ID', value: 'ID - Idaho' },
  { id: 'IL', value: 'IL - Illinois' },
  { id: 'IN', value: 'IN - Indiana' },
  { id: 'KS', value: 'KS - Kansas' },
  { id: 'KY', value: 'KY - Kentucky' },
  { id: 'LA', value: 'LA - Louisiana' },
  { id: 'MA', value: 'MA - Massachusetts' },
  { id: 'MD', value: 'MD - Maryland' },
  { id: 'ME', value: 'ME - Maine' },
  { id: 'MI', value: 'MI - Michigan' },
  { id: 'MN', value: 'MN - Minnesota' },
  { id: 'MO', value: 'MO - Missouri' },
  { id: 'MS', value: 'MS - Mississippi' },
  { id: 'MT', value: 'MT - Montana' },
  { id: 'NC', value: 'NC - North Carolina' },
  { id: 'ND', value: 'ND - North Dakota' },
  { id: 'NE', value: 'NE - Nebraska' },
  { id: 'NH', value: 'NH - New Hampshire' },
  { id: 'NJ', value: 'NJ - New Jersey' },
  { id: 'NM', value: 'NM - New Mexico' },
  { id: 'NV', value: 'NV - Nevada' },
  { id: 'NY', value: 'NY - New York' },
  { id: 'OH', value: 'OH - Ohio' },
  { id: 'OK', value: 'OK - Oklahoma' },
  { id: 'OR', value: 'OR - Oregon' },
  { id: 'PA', value: 'PA - Pennsylvania' },
  { id: 'RI', value: 'RI - Rhode Island' },
  { id: 'SC', value: 'SC - South Carolina' },
  { id: 'SD', value: 'SD - South Dakota' },
  { id: 'TN', value: 'TN - Tennessee' },
  { id: 'TX', value: 'TX - Texas' },
  { id: 'UT', value: 'UT - Utah' },
  { id: 'VA', value: 'VA - Virginia' },
  { id: 'VT', value: 'VT - Vermont' },
  { id: 'WA', value: 'WA - Washington' },
  { id: 'WI', value: 'WI - Wisconsin' },
  { id: 'WV', value: 'WV - West Virginia' },
  { id: 'WY', value: 'WY - Wyoming' },
];

const CustomerV2Info = ({ isSubmitting }) => (
  <div className='customer-info'>
    <div className='section-header'>
      <strong>Contact Information:</strong>
    </div>
    <div className='form-row flex-row'>
      <div className='field required col col-full'>
        <label htmlFor='email'>
          <span>Your Email Address:</span>
        </label>
        <Field id='email' name='email' type='email' />
        <ErrorMessage name='email'>{(msg) => <div className='error-msg'>{msg}</div>}</ErrorMessage>
      </div>
    </div>
    <div className='form-row flex-row'>
      <div className='field required col col-span4'>
        <label htmlFor='firstName'>
          <span>Your First Name:</span>
        </label>
        <Field id='firstName' name='firstName' type='text' autoComplete='off' />
        <ErrorMessage name='firstName'>{(msg) => <div className='error-msg'>{msg}</div>}</ErrorMessage>
      </div>
      <div className='field required col col-span4'>
        <label htmlFor='lastName'>
          <span>Your Last Name:</span>
        </label>
        <Field id='lastName' name='lastName' type='text' autoComplete='off' />
        <ErrorMessage name='lastName'>{(msg) => <div className='error-msg'>{msg}</div>}</ErrorMessage>
      </div>
      <div className='field required col col-span4'>
        <label htmlFor='phoneNumber'>
          <span>Your Phone Number:</span>
        </label>
        <Field name='phoneNumber'>
          {({ field }) => {
            return (
              <InputMask
                id='phoneNumber'
                name='phoneNumber'
                type='tel'
                autoComplete='off'
                mask='(999) 999-9999'
                alwaysShowMask={true}
                onChange={field.onChange}
                value={field.value}
              />
            );
          }}
        </Field>
        <ErrorMessage name='phoneNumber'>{(msg) => <div className='error-msg'>{msg}</div>}</ErrorMessage>
      </div>
    </div>
    <div className='form-row flex-row'>
      <div className='field col col-span4'>
        <label htmlFor='company'>
          <span>Your Customer&apos;s Company Name:</span>
        </label>
        <Field id='company' name='company' type='text' autoComplete='off' />
      </div>
      <div className='field required col col-span4'>
        <label htmlFor='city'>
          <span>Your Customer&apos;s City:</span>
        </label>
        <Field id='city' name='city' type='text' autoComplete='off' />
        <ErrorMessage name='city'>{(msg) => <div className='error-msg'>{msg}</div>}</ErrorMessage>
      </div>
      <div className='field required col col-span4'>
        <Field name='province' label="Your Customer's State:" component={Select} options={states} />
        <ErrorMessage name='province'>{(msg) => <div className='error-msg'>{msg}</div>}</ErrorMessage>
      </div>
    </div>
    <div className='form-row flex-row'>
      <div className='field col col-full'>
        <label htmlFor='notes'>
          <span>Notes:</span>
        </label>
        <Field id='notes' name='notes' component='textarea' />
      </div>
    </div>
    <div className='form-row btn-row align-right'>
      <LoadingButton className={`primary`} name='login-button' type='submit' loading={isSubmitting}>
        <span>Submit</span>
      </LoadingButton>
    </div>
  </div>
);

export default CustomerV2Info;

CustomerV2Info.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
};
