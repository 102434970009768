import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const StyledFeatureCallout = styled.div`
  box-sizing: border-box;
  flex: 50%;
  margin: 3rem 0;
  padding: 1rem;
  text-align: center;
  ol,
  ul {
    color: ${({ theme }) => theme.secondary};
    display: inline-block;
    font-size: 1rem;
    line-height: 1.2;
    margin: 0;
    padding-left: 1.4rem;
    text-align: left;
    li {
      margin: 1.2rem 0;
    }
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    thead {
      th {
        background-color: ${({ theme }) => theme.secondary};
        border-bottom: 1px solid ${({ theme }) => darken(0.1, theme.secondary)};
        color: #ffffff;
        font-size: 1.2rem;
        font-weight: normal;
        padding: 0.6% 3%;
        text-align: left;
      }
    }
    tbody {
      tr {
        text-align: left;
        th,
        td {
          font-size: 1rem;
          font-weight: normal;
          padding: 1% 3%;
        }
        th {
          background-color: ${({ theme }) => theme.table.label_odd || theme.primary};
          border-bottom: 1px solid ${({ theme }) => darken(0.05, theme.table.label_odd || theme.primary)};
          color: #ffffff;
          text-transform: capitalize;
        }
        td {
          background-color: ${({ theme }) => theme.table.value_odd || lighten(0.4, theme.primary)};
          border-bottom: 1px solid ${({ theme }) => darken(0.05, theme.table.value_odd || lighten(0.4, theme.primary))};
          & > p {
            margin: 0;
            & ~ p {
              margin: 5px 0 0;
            }
            & + p {
              margin: 5px 0 0;
            }
          }
          &[colspan] {
            font-size: 1.2rem;
            font-weight: 700;
            text-align: center;
          }
        }
        &:nth-child(even) {
          th {
            background-color: ${({ theme }) => theme.table.label_even || lighten(0.05, theme.primary)};
            border-bottom: 1px solid
              ${({ theme }) => darken(0.05, theme.table.label_even || lighten(0.05, theme.primary))};
          }
          td {
            background-color: ${({ theme }) => theme.table.value_even || lighten(0.5, theme.primary)};
            border-bottom: 1px solid
              ${({ theme }) => darken(0.05, theme.table.value_even || lighten(0.5, theme.primary))};
          }
        }
      }
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mobile_rg}) {
    flex: 100%;
    margin: 1rem 0;
    padding: 0;
  }
`;
