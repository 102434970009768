import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Image from '../../components/Image';
import CatalogContext from '../../contexts/catalog/catalog-context';

const CategoryItem = ({ category }) => {
  const location = useLocation();
  const context = useContext(CatalogContext);
  const { catalog } = context.state;
  const info = catalog.categories[category.category_id];
  const toLink = typeof location.pathname === 'undefined' ? `/c/${info.path}` : `${location.pathname}/${info.path}`;

  return (
    <Link className='category-item' to={toLink}>
      <div className='category-image'>
        <Image src={`imgs/category/${info.image}`} width={600} height={600} />
      </div>
      <p className='category-name'>{info.name}</p>
    </Link>
  );
};
export default CategoryItem;

CategoryItem.propTypes = {
  category: PropTypes.object.isRequired,
};
