import { useContext, useEffect, useState } from 'react';
import ErrorPage from './ErrorPage';
import PageWrapper from './global/PageWrapper';
import CategoryListing from './category/CategoryListing';
import ProductListing from './category/ProductListing';
import CatalogContext from '../contexts/catalog/catalog-context';
import { hasOwnProp } from '../utilities/generic';
import { useLocation, useNavigate } from 'react-router-dom';

const CategoryPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const context = useContext(CatalogContext);
  const { catalog } = context.state;
  const catParams = location.pathname.substring(1).split('/').slice(1);
  const [category, setCategory] = useState(context.actions.getCurrentCategory(location.pathname));

  const reduceProductIds = (pids, product) => {
    pids.push(product.product_id);
    return pids;
  };

  const subcategories = (category && category.categories) || null;
  const categoryClassName = subcategories ? 'clp' : 'plp';
  const categoryInfo = catalog.categories[category.category_id];
  const products = (category && category.products) || null;
  const productIds = products && products.reduce(reduceProductIds, []);

  useEffect(() => {
    let nextPage = category;
    let nextPageIsProduct = false;

    // check if category has only one sub-category. If so, go down the tree to find the next category with multiple sub-categories
    if (nextPage && nextPage.categories && Object.keys(nextPage.categories).length === 1) {
      while (hasOwnProp(nextPage, 'categories') && Object.keys(nextPage.categories).length === 1) {
        nextPage = nextPage.categories[Object.keys(nextPage.categories)[0]];
      }
    }

    // check if category has more than one product
    if (nextPage && nextPage.products && Object.keys(nextPage.products).length === 1) {
      nextPage = nextPage.products[Object.keys(nextPage.products)[0]];
      nextPageIsProduct = true;
    }

    // get data based on nextPage variable and push into Environment
    if (nextPage !== category) {
      if (nextPageIsProduct) {
        const catPaths = catParams ? catParams.join('/') : '';
        navigate(catPaths.length === '' ? `/p/${nextPage.path}` : `/p/${catPaths}/${nextPage.path}`, {
          replace: true,
        });
      } else {
        setCategory(nextPage);
      }
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!products && !subcategories) {
    return <ErrorPage type='no-items' title={(category && category.name) || ''} />;
  }

  return (
    <PageWrapper>
      <div className={`category page-wrapper ${categoryClassName}`}>
        {!products ? (
          <CategoryListing category={category} />
        ) : (
          <ProductListing heading={categoryInfo.name} productIds={productIds} />
        )}
      </div>
    </PageWrapper>
  );
};
export default CategoryPage;

CategoryPage.propTypes = {};
