import { Children } from 'react';
import PropTypes from 'prop-types';

const TabPanel = ({ children }) => {
  return (
    <div className='tab-panel'>
      {Children.map(children, (child, i) => {
        return <div key={i}>{child} </div>;
      })}
    </div>
  );
};
export default TabPanel;

TabPanel.propTypes = {
  children: PropTypes.node,
  tabid: PropTypes.string.isRequired,
};
