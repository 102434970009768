import styled from 'styled-components';

export const StyledTabBarTabList = styled.ul`
  border: none;
  display: flex;
  flex-flow: row nowrap;
  list-style: none;
  margin: 0;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;
  user-select: none;
  touch-action: pan-x;
  -webkit-touch-callout: none;
  width: 100%;
  // fix for IE11
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    min-width: 1px;
    margin-top: auto;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
  }
`;
