import { useContext, useEffect } from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginForm from '../forms/login/LoginForm';
import AppContext from '../contexts/app/app-context';
import { AUTHENTICATED } from '../contexts/app/app-reducer';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(AppContext);
  const { apiUrl, isLoggedIn } = context.state;
  // initial form values
  const initialValues = {
    message: '',
    messageType: '',
    password: '',
    rememberMe: false,
    revealPassword: false,
  };

  // redirect user if loggedin
  // purposefully leaving off location as a dependency
  useEffect(() => {
    if (!isLoggedIn) {
      return false;
    }

    const { from } = location.state || { from: { pathname: '/' } };
    const path = from.pathname === '/login' ? '/' : from.pathname;

    if (path === location.pathname) {
      return false;
    }

    navigate(path, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const handleSubmit = (values, { setSubmitting, setValues }) => {
    const formData = {
      password: values.password,
      rememberMe: values.rememberMe ? 'on' : 'off',
    };

    axios
      .post(`${apiUrl}/company/ajax/login-post`, formData, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((response) => formSubmitSuccess(response, values, setValues))
      .catch(({ response }) => formSubmitError(response, values, setValues))
      .then(() => setSubmitting(false));
  };

  const formSubmitError = (res, values, setValues) => {
    try {
      const message = res.data.message || res.request.responseText;
      setValues({ ...values, message, messageType: 'error' });
    } catch (err) {
      console.error(err);
    }
  };

  const formSubmitSuccess = (res, values, setValues) => {
    try {
      const { messageType = 'error' } = res.data;

      if (messageType !== 'success') {
        return formSubmitError(res, values, setValues);
      }

      context.dispatch({ type: AUTHENTICATED, payload: { isLoggedIn: true } });
    } catch (err) {
      console.error(err);
      return formSubmitError({ data: { message: err } }, values, setValues);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formActions) => (
        <div className='page login-page'>
          <div className='login-card'>
            <div className='form-title'>IPG Login</div>
            <LoginForm {...formActions} />
          </div>
        </div>
      )}
    </Formik>
  );
};
export default Login;
