import { animated, useTransition } from '@react-spring/web';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import {
  AppSelectorProtectedRoute,
  DownloadIpgProtectedRoute,
  LoginProtectedRoute,
  QuoteListProtectedRoute,
} from './route-types';

// pages
import ApplicationSelector from '../pages/ApplicationSelector';
import CategoryPage from '../pages/CategoryPage';
import Download from '../pages/Download';
import ErrorPage from '../pages/ErrorPage';
import Login from '../pages/Login';
import ProductPage from '../pages/ProductPage';
import QuoteListPage from '../pages/QuoteListPage';
import SplashPage from '../pages/SplashPage';

const Main = () => {
  const location = useLocation();
  const transitions = useTransition(location, {
    keys: (location) => location.pathname,
    from: { opacity: 0, position: 'absolute', width: '100%', top: 0, left: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 450 },
  });

  useEffect(() => {
    const pageView = {
      event: 'Pageview',
      pagePath: location.pathname,
      pageTitle: document.title,
    };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(pageView);
  }, [location.pathname]);

  return transitions((styles, item) => (
    <animated.div key={item.key} style={styles}>
      <Routes location={item}>
        <Route path='/login' element={<Login />} />
        <Route
          path='/'
          element={
            <LoginProtectedRoute>
              <SplashPage />
            </LoginProtectedRoute>
          }
        />
        {['/c', '/c/*'].map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <LoginProtectedRoute>
                <CategoryPage />
              </LoginProtectedRoute>
            }
          />
        ))}
        <Route
          path='/p/*'
          element={
            <LoginProtectedRoute>
              <ProductPage />
            </LoginProtectedRoute>
          }
        />
        {['/application-selector', '/application-selector/:answers'].map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <AppSelectorProtectedRoute>
                <ApplicationSelector />
              </AppSelectorProtectedRoute>
            }
          />
        ))}
        <Route
          path='/download'
          element={
            <DownloadIpgProtectedRoute>
              <Download />
            </DownloadIpgProtectedRoute>
          }
        />
        <Route
          path='/my-list'
          element={
            <QuoteListProtectedRoute>
              <QuoteListPage />
            </QuoteListProtectedRoute>
          }
        />
        <Route path='*' element={<ErrorPage title='Page Not Found' type='nonexistent-page' />} />
      </Routes>
    </animated.div>
  ));
};
export default Main;
