import { useTransition } from '@react-spring/web';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import Image from '../../../components/Image';
import {
  StyledSwatches,
  StyledSwatchHeroImageWrapper,
  StyledSwatchTitle,
  StyledSwatchWrapper,
} from '../../../components/styled/content/StyledSwatchSelection';
import { StyledTitleH1 } from '../../../components/styled/StyledTitle';
import { capitalizeEachWord, getRawMarkup } from '../../../utilities/generic';
import Swatch from './Swatch';

const SwatchSelection = ({ content, product }) => {
  const [state, setState] = useState({
    activeOption: 0,
    activeOptionType: content.type_filter.split(',')[0],
  });
  const swatchClick = (index, optionType) => setState({ activeOption: index, activeOptionType: optionType });

  const basePath = 'imgs/products/options';
  const { activeOption, activeOptionType } = state;
  const productOptions = content.type_filter.split(',');
  const activeOpt = product.options[activeOptionType] && product.options[activeOptionType][activeOption];

  const transitions = useTransition(activeOpt, {
    keys: (item) => item.label,
    initial: { opacity: 1, position: 'relative', zIndex: 0 },
    from: { opacity: 0, position: 'relative', zIndex: 1 },
    enter: { opacity: 1, position: 'relative', zIndex: 0 },
    leave: { opacity: 0, position: 'absolute', zIndex: 0 },
    config: { duration: 300 },
  });

  return (
    <Fragment>
      <StyledTitleH1>{content.name.replace('#name#', product.name)}</StyledTitleH1>
      {content.content !== '' && (
        <div className='asterisk-content' dangerouslySetInnerHTML={getRawMarkup(content.content)} />
      )}
      <div className='product-section swatch-selection'>
        <div className='left-col'>
          <div className='product-image swatch-hero-image'>
            {transitions((styles, item) => (
              <StyledSwatchHeroImageWrapper key={item.key} style={styles}>
                <Image alt={item.label} src={`${basePath}/${item.image}`} height={600} width={600} />
              </StyledSwatchHeroImageWrapper>
            ))}
          </div>
        </div>
        <div className='right-col'>
          {productOptions.map((type) => (
            <StyledSwatchWrapper key={type}>
              <StyledSwatchTitle>{capitalizeEachWord(type.split('_').join(' '))}</StyledSwatchTitle>
              <StyledSwatches>
                {product.options[type] &&
                  product.options[type].map(
                    (option, i) =>
                      option.value !== 'clear' && (
                        <Swatch
                          index={i}
                          key={option.value}
                          option={option}
                          optionType={type}
                          isActive={i === activeOption && type === activeOptionType}
                          swatchClick={swatchClick}
                        />
                      )
                  )}
              </StyledSwatches>
            </StyledSwatchWrapper>
          ))}
        </div>
      </div>
    </Fragment>
  );
};
export default SwatchSelection;

SwatchSelection.propTypes = {
  content: PropTypes.shape({
    content: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    type_filter: PropTypes.string.isRequired,
  }),
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    options: PropTypes.object.isRequired,
  }),
};
