import styled from 'styled-components';

export const StyledTabMinimizer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.tab_bar_minimize_bg || theme.primary};
  bottom: 100%;
  border-radius: 0.4rem 0.4rem 0 0;
  box-shadow: 0px -1px 6px 2px rgba(0, 0, 0, 0.3);
  color: ${({ theme }) => theme.tab_bar_minimize_txt || '#ffffff'};
  cursor: pointer;
  display: flex;
  font-size: 2rem;
  justify-content: center;
  padding: 0.4rem 1.6rem;
  position: absolute;
  right: 4px;
  &:after {
    content: '${(props) => (props.minimize ? '\f0d8' : '\f0d7')}';
    font-family: 'FontAwesome';
  }
`;
