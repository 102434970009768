import PropTypes from 'prop-types';
import ProductAndCategoryCarousel from '../../components/ProductAndCategoryCarousel';
import { StyledTitleH1Category } from '../../components/styled/StyledTitle';

const ProductListing = ({ heading, productIds }) => (
  <div className='product-listing-page'>
    {heading && <StyledTitleH1Category>{heading}</StyledTitleH1Category>}
    <ProductAndCategoryCarousel productIds={productIds} />
  </div>
);

export default ProductListing;

ProductListing.propTypes = {
  heading: PropTypes.string,
  productIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};
