import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const StyledActionbarButton = styled.button`
  border-radius: 0.4rem;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  color: $gray23;
  cursor: pointer;
  height: auto;
  font-size: 0.9rem;
  font-weight: 600;
  min-height: 40px;
  min-width: 80px;
  padding: 5px 8px;
  text-align: center;
  transition: 0.4s ease-in-out background, 0.4s ease-in-out border;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mobile_rg}) {
    flex: 1 1 50%;
  }
`;

export const StyledPrimaryActionButton = styled(StyledActionbarButton)`
  background-color: ${({ theme }) => theme.primary_action};
  border-color: ${({ theme }) => darken(0.05, theme.primary_action)};
  color: #ffffff;

  &:hover {
    background-color: ${({ theme }) => lighten(0.1, theme.primary_action)};
  }
`;

export const StyledSecondaryActionButton = styled(StyledActionbarButton)`
  background-color: #c0c0c0;
  border-color: ${() => darken(0.07, '#c0c0c0')};
  margin-right: 2rem;

  &:hover {
    background-color: ${() => lighten(0.05, '#c0c0c0')};
  }
`;

export const StyledSecondaryOutlineActionButton = styled(StyledActionbarButton)`
  background-color: #f9f9f9;
  border-color: #999999;

  &:hover {
    background-color: #ffffff;
    border-color: ${() => darken(0.1, '#999999')};
  }
`;

export const StyledShareOutlineButton = styled(StyledSecondaryOutlineActionButton)`
  span:before {
    content: '\f1e0';
    font-family: 'FontAwesome';
    font-weight: 500;
    margin-right: 5px;
  }
`;

export const StyledResetOutlineButton = styled(StyledSecondaryOutlineActionButton)`
  span:before {
    content: '\f021';
    font-family: 'FontAwesome';
    font-weight: 500;
    margin-right: 5px;
  }
`;

export const StyledDangerActionButton = styled(StyledActionbarButton)`
  background-color: #b22222;
  border-color: ${() => darken(0.12, '#b22222')};
  color: #ffffff;

  &:hover {
    background-color: ${() => lighten(0.1, '#b22222')};
  }
`;

export const StyledClearAllButton = styled(StyledDangerActionButton)`
  margin-right: 2rem;
  span:before {
    content: '\f1f8';
    font-family: 'FontAwesome';
    font-weight: 500;
    margin-right: 5px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mobile_rg}) {
    margin-right: 2rem;
  }
`;
