import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { StyledSwatch } from '../../../components/styled/content/StyledSwatchSelection';

const Swatch = ({ index, isActive, option, optionType, swatchClick }) => {
  const refSwatch = useRef(null);
  const { label, value } = option;

  useEffect(() => {
    let yiq = 200;
    if (value !== null && value !== '') {
      const [r, g, b] = value.match(/\d+/g);
      yiq = (r * 299 + g * 587 + b * 114) / 1000;
    }
    refSwatch.current.style.color = yiq >= 128 ? '#000' : '#fff';
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledSwatch key={value} isActive={isActive} onClick={swatchClick.bind(this, index, optionType)}>
      <span ref={refSwatch} style={{ backgroundColor: value }}>
        {label}
      </span>
    </StyledSwatch>
  );
};
export default Swatch;

Swatch.propTypes = {
  index: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  option: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  optionType: PropTypes.string.isRequired,
  swatchClick: PropTypes.func.isRequired,
};
