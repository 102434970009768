import { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import VideoModal from '../../components/modal/VideoModal';
import AppContext from '../../contexts/app/app-context';
import {
  StyledAskTheExpert,
  StyledAskTheExpertBlock,
  StyledAskTheExpertBlockWithImage,
  StyledAskTheExpertImage,
  StyledAskTheExpertNumber,
  StyledAskTheExpertQuestion,
} from '../../components/styled/product/StyledProductAskTheExpert';
import { StyledTitleH1 } from '../../components/styled/StyledTitle';

const AskTheExpert = ({ product, videos }) => {
  const context = useContext(AppContext);
  const { isOffline } = context.state;
  const { name } = product;

  let videosPerRow = useMemo(() => {
    let numInRow = 5;
    while (videos.length % numInRow !== 0 && numInRow > 3) {
      numInRow = numInRow - 1;
    }
    return numInRow;
  }, [videos]);

  return (
    <div className='content-page'>
      <StyledTitleH1>Ask The Expert: {name}</StyledTitleH1>
      <StyledAskTheExpert>
        {videos.map((video, i) => {
          const ExpertBlockComponent =
            video.image !== null ? StyledAskTheExpertBlockWithImage : StyledAskTheExpertBlock;
          return (
            <VideoModal key={video.video_id} video={video} isOffline={isOffline}>
              <ExpertBlockComponent className={`show${videosPerRow}`}>
                {video.image !== null && <StyledAskTheExpertImage image={video.image} />}
                <StyledAskTheExpertNumber>
                  <span>{i + 1}</span>
                </StyledAskTheExpertNumber>
                <StyledAskTheExpertQuestion>{video.name}</StyledAskTheExpertQuestion>
              </ExpertBlockComponent>
            </VideoModal>
          );
        })}
      </StyledAskTheExpert>
    </div>
  );
};
export default AskTheExpert;

AskTheExpert.propTypes = {
  videos: PropTypes.array.isRequired,
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};
