export const DEFAULT_THEME = {
  primary: '#06a1e0',
  primary_action: '#ffa500',
  secondary: '#787878',
  breadcrumb: '#035486',
  breadcrumb_mode: 'darken',
  header_counter_txt: '#ffffff',
  backbutton_txt: '#ffffff',
  table: {},
  breakpoints: {
    mobile_sm: '320px',
    mobile_rg: '480px',
    tablet_sm: '600px',
    tablet_rg: '768px',
    desktop_sm: '960px',
    desktop_rg: '1200px',
    desktop_lg: '1470px',
    large_screen: '2000px',
  },
};

const companyTheme = window?.ipgApp?.company?.theme_colors || {};

export const theme = { ...DEFAULT_THEME, ...companyTheme };
