import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Image from '../../components/Image';
import { getRawMarkup } from '../../utilities/generic';
import {
  StyledProductCard,
  StyledProductCardDesc,
  StyledProductCardImage,
  StyledProductCardInfo,
  StyledProductCardItem,
  StyledProductCardLink,
  StyledProductCardName,
  StyledProductCardSku,
} from '../../components/styled/slider';

const ProductCard = ({ allowClicks, className, product, showDesc }) => {
  const location = useLocation();
  const catParams = location.pathname.substring(1).split('/').slice(1);
  const toLink = catParams.length > 0 ? `/p/${catParams.join('/')}/${product.path}` : `/p/${product.path}`;

  const handleClick = (ev) => {
    if (!allowClicks) {
      ev.preventDefault();
      ev.stopPropagation();
    }
  };

  return (
    <StyledProductCardItem className={className}>
      <StyledProductCardLink onClick={handleClick} to={toLink} data-productid={product.product_id}>
        <StyledProductCard>
          <StyledProductCardImage>
            <Image src={`imgs/products/${product.image}`} width={600} height={600} />
          </StyledProductCardImage>
          <StyledProductCardInfo hideLine={!showDesc}>
            <StyledProductCardName>{product.name}</StyledProductCardName>
            {product.sku && <StyledProductCardSku>{product.sku}</StyledProductCardSku>}
          </StyledProductCardInfo>
          {showDesc && <StyledProductCardDesc dangerouslySetInnerHTML={getRawMarkup(product.desc)} />}
        </StyledProductCard>
      </StyledProductCardLink>
    </StyledProductCardItem>
  );
};
export default ProductCard;

ProductCard.propTypes = {
  allowClicks: PropTypes.bool,
  className: PropTypes.string,
  product: PropTypes.object.isRequired,
  showDesc: PropTypes.bool,
};

ProductCard.defaultProps = {
  allowClicks: true,
  className: '',
  showDesc: true,
};
