import { useContext, useEffect, useLayoutEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useLocation } from 'react-router-dom';
import CatalogContext from '../contexts/catalog/catalog-context';
import { hasOwnProp } from '../utilities/generic';
import { StyledBreadcrumb, StyledBreadcrumbLink, StyledBreadcrumbs } from './styled/StyledBreadcrumbs';

const Breadcrumbs = ({ isErrorPage }) => {
  const breadcrumbBar = useRef();
  const params = useParams();
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const context = useContext(CatalogContext);
  const { catalog } = context.state;

  // scroll bar to the end of the list (mainly for mobile)
  useLayoutEffect(() => {
    breadcrumbBar.current.scrollLeft = breadcrumbBar.current.scrollWidth;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbBar.current]);

  // get breadcrumb info based on url
  useEffect(() => {
    let newBreadcrumbs = [];
    let paths = location.pathname.substring(1).split('/');
    const lastPathPart = paths[paths.length - 1];
    const isCategory = paths[0] === 'c';
    const isHomePage = paths[0] === 'c' && paths.length === 1;
    const isProduct = paths[0] === 'p';
    const appSelectorPath = params.answers || null;

    // insert the first link which is the homepage
    newBreadcrumbs.push(isHomePage ? { name: 'Home' } : { name: 'Home', to: '/c' });

    try {
      // handle category/categories
      if ((isCategory || isProduct) && paths.length > 1) {
        const categoryPaths = isProduct ? paths.slice(1, -1) : paths.slice(1);
        categoryPaths.reduce((catPaths, path) => {
          const info = Object.values(catalog.categories).find((cat) => cat.path === path);

          // return if the path is not found/undefined
          if (!info) return catPaths;

          catPaths.push(path);
          const current = `/c/${catPaths.join('/')}`;
          const breadcrumb = { name: info.name };
          newBreadcrumbs.push(location.pathname === current ? breadcrumb : { ...breadcrumb, to: current });
          return catPaths;
        }, []);
      }

      // handle product
      if (isProduct) {
        const productInfo = Object.values(catalog.products).find((prod) => prod.path === lastPathPart);
        const prodPath = `/p/${paths.join('/')}/${lastPathPart}`;
        const breadcrumb = { name: productInfo.name };
        newBreadcrumbs.push(location.pathname === prodPath ? breadcrumb : { ...breadcrumb, to: prodPath });
      }

      // appliation selector URL
      if (appSelectorPath) {
        const name = location.pathname
          .toLowerCase()
          .substring(1)
          .split('/')
          .slice(0, 1)
          .map((path) => path.charAt(0).toUpperCase() + path.substring(1))
          .join(' ')
          .split('-')
          .map((path) => path.charAt(0).toUpperCase() + path.substring(1))
          .join(' ');
        newBreadcrumbs.push({ name: name });
      }

      // set any direct landing pages
      if (!isCategory && !isProduct && !appSelectorPath && !isHomePage && !isErrorPage) {
        const landingPageName = location.pathname
          .toLowerCase()
          .substring(1)
          .split('/')
          .map((path) =>
            path
              .split('-')
              .map((path) => path.charAt(0).toUpperCase() + path.substring(1))
              .join(' ')
          )
          .join(' ');
        newBreadcrumbs.push({ name: landingPageName });
      }

      setBreadcrumbs(newBreadcrumbs);
    } catch (err) {
      console.log(err);
      setBreadcrumbs(newBreadcrumbs);
    }
  }, [catalog.categories, catalog.products, isErrorPage, location.pathname, params.answers]);

  return (
    <StyledBreadcrumbs ref={breadcrumbBar}>
      {breadcrumbs.map((crumb, index) => {
        if (hasOwnProp(crumb, 'to')) {
          return (
            <StyledBreadcrumbLink as={Link} to={crumb.to} index={index} key={index}>
              {index === 0 && <span className='home-icon' aria-hidden='true' />}
              <span className='text'>{crumb.name}</span>
            </StyledBreadcrumbLink>
          );
        }

        return (
          <StyledBreadcrumb index={index} key={index}>
            {index === 0 && <span className='home-icon' aria-hidden='true' />}
            <span className='text'>{crumb.name}</span>
          </StyledBreadcrumb>
        );
      })}
    </StyledBreadcrumbs>
  );
};

export default Breadcrumbs;

Breadcrumbs.propTypes = {
  isErrorPage: PropTypes.bool,
};

Breadcrumbs.defaultProps = {
  isErrorPage: false,
};

/*
<Link className="breadcrumb" to={crumb.to} index={index} key={index}>
  {index === 0 && <span className="home-icon" aria-hidden="true" />}
  <span className="text">{crumb.name}</span>
</Link>
*/
