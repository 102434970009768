import { useContext } from 'react';
import PropTypes from 'prop-types';
import Select from '../../components/Select';
import { hasOwnProp } from '../../../utilities/generic';
import { AppSelectorContext } from '../../../contexts/appselector';

const AppSelectorQuestion = ({ question, index, editQuestion = null }) => {
  const context = useContext(AppSelectorContext);
  const { answers, questionPath, isAdmin } = context.state;

  const handleChange = (ev) => {
    const newQuestionPath = questionPath[index] ? questionPath.slice(0, index + 1) : questionPath.slice();
    const answer = question.answers.find((item) => item.answer_id === ev.target.value);
    const hasNext = hasOwnProp(answer, 'next') && answer.next !== null;

    const newAnswers = newQuestionPath.reduce((newAnswers, qid, index) => {
      newAnswers[`question${qid}`] =
        index + 1 === newQuestionPath.length ? answer.answer_id : answers[`question${qid}`];
      return newAnswers;
    }, {});

    if (answer.type === 'link') {
      newQuestionPath.push(answer.next);
    }

    context.actions.updateValue('questionPath', newQuestionPath); // set question path
    context.actions.updateValue('answers', newAnswers); // set question answers
    context.actions.updateValue('isResult', !hasNext); // set to true if there's no next value
    context.actions.updateValue(ev.target.name, ev.target.value); // set select to value

    if (context.state.updateGlobalState && typeof context.state.updateGlobalState === 'function') {
      const updatedState = {
        answers: newAnswers || answers,
        questionPath: newQuestionPath || questionPath,
        isResult: !hasNext,
      };
      context.state.updateGlobalState(updatedState);
    }
  };

  return (
    <div className='field'>
      <Select
        name={`answers.question${question.question_id}`}
        value={answers[`question${question.question_id}`] || ''}
        options={question.answers}
        label={question.text}
        optKey='answer_id'
        onChange={handleChange}
      />
      {isAdmin && editQuestion && (
        <button className='' type='button' data-question={question.question_id} onClick={editQuestion}>
          <span>Edit</span>
        </button>
      )}
    </div>
  );
};

export default AppSelectorQuestion;

AppSelectorQuestion.propTypes = {
  index: PropTypes.number.isRequired,
  question: PropTypes.shape({
    answers: PropTypes.array.isRequired,
    question_id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  editQuestion: PropTypes.func,
};

/*
const meetsCriteria = condition => {
	const { check, operator } = condition;
	return operator === "and"
		? check.reduce((status, rule) => status && answers[`question${rule.question}`] === rule.answer, true)
		: check.reduce((status, rule) => status || answers[`question${rule.question}`] === rule.answer, false);
};

const determineVisibility = (condition, answer) => {
	const satisfied = meetsCriteria(condition, answer);
	return condition.action === "hide" ? !satisfied : satisfied;
};

const getOptions = () => {
	// question has no conditions, include all as options
	if (!question.hasConditions) {
		return question.answers;
	}

	// filter out which answers should be shown as results, based on conditions
	var filteredAnswers = question.answers.filter(answer => {
		const condition = conditions.find(condition => condition.condition_id === answer.condition);
		return !condition ? true : determineVisibility(condition, answer);
	});

	return filteredAnswers;
};
*/
