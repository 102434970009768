import styled from 'styled-components';

export const StyledBackButton = styled.button`
  background-color: ${({ theme }) => theme.back_button_bg || theme.primary};
  border: none;
  border-radius: 100%;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  bottom: 1rem;
  box-sizing: border-box;
  color: ${({ theme }) => theme.back_button_txt || '#ffffff'};
  cursor: pointer;
  height: 5.6rem;
  left: -3rem;
  outline: none;
  overflow: hidden;
  position: fixed;
  text-decoration: none;
  transition: 0.25s linear all;
  white-space: nowrap;
  width: 5.8rem;
  z-index: 7;
  &:before {
    content: '\f053';
    font-family: 'FontAwesome';
    font-size: 2rem;
    right: 1rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.primary_action};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mobile_rg}) {
    bottom: 0.5rem;
    height: 4.8rem;
    left: -2.8rem;
    width: 5.6rem;
  }
`;
