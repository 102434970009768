import { Children } from 'react';
import PropTypes from 'prop-types';

const CategoryGridColumn = ({ children, vertical }) => {
  const splitClass = Children.count(children) > 1 ? 'split' : 'nosplit';
  const alignClass = vertical ? 'vert' : 'horz';

  return (
    <div className={`category-column ${splitClass} ${alignClass}`}>{Children.map(children, (child) => child)}</div>
  );
};
export default CategoryGridColumn;

CategoryGridColumn.propTypes = {
  children: PropTypes.node.isRequired,
  vertical: PropTypes.bool,
};
