import { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useCarousel } from '../hooks';
import CatalogContext from '../contexts/catalog/catalog-context';
import { CAROUSEL_SLIDEINDEX } from '../contexts/catalog/catalog-reducer';
import CategoryCard from '../pages/catalog/CategoryCard';
import ProductCard from '../pages/catalog/ProductCard';
import { StyledSlider } from './styled/slider';

const ProductAndCategoryCarousel = ({
  categoryIds,
  productIds,
  settings: userSettings,
  showProductDesc,
  rememberSliderPosition,
}) => {
  const location = useLocation();
  const context = useContext(CatalogContext);
  const { categories, products } = context.state.catalog;

  // remember current viewed index based on current path
  const handleAfterSlide = (slideIndex) => {
    context.dispatch({
      type: CAROUSEL_SLIDEINDEX,
      payload: { pathname: location.pathname, slideIndex: slideIndex },
    });
  };

  // get carousel settings and current click status
  const { allowClicks, settings } = useCarousel({
    ...userSettings,
    afterChange: rememberSliderPosition ? handleAfterSlide : null,
    className: 'product-listing-items',
  });

  // get carousel items (products and categories)
  const getCarouselItems = useMemo(() => {
    const items = [];

    productIds.reduce((res, pid) => {
      const product = products[pid];
      if (product) {
        res.push(<ProductCard key={pid} product={product} allowClicks={allowClicks} showDesc={showProductDesc} />);
      }
      return res;
    }, items);

    categoryIds.reduce((res, cid) => {
      const category = categories[cid];
      if (category) {
        res.push(<CategoryCard key={cid} category={category} allowClicks={allowClicks} />);
      }
      return res;
    }, items);

    return items;
  }, [allowClicks, categories, categoryIds, productIds, products, showProductDesc]);

  return <StyledSlider {...settings}>{getCarouselItems}</StyledSlider>;
};

ProductAndCategoryCarousel.propTypes = {
  categoryIds: PropTypes.array,
  productIds: PropTypes.array,
  settings: PropTypes.object,
  rememberSliderPosition: PropTypes.bool,
  showProductDesc: PropTypes.bool,
};

ProductAndCategoryCarousel.defaultProps = {
  categoryIds: [],
  productIds: [],
  settings: {},
  showProductDesc: true,
  rememberSliderPosition: true,
};

export default ProductAndCategoryCarousel;
