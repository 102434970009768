import { createContext } from "react";

const AppContext = createContext({
	apiUrl: "",
	appSelector: {
		answers: {},
		questionPath: [],
		isResult: false,
	},
	company: { code: "spiral", name: "Spiral", logo: "spiral-logo.svg" },
	csfr: { key: "csfr_token", token: "" },
	currentYear: new Date().getFullYear(),
	enableApplications: false,
	enableQuoting: true,
	enableDownload: true,
	quoteFormType: "reseller",
	isLoggedIn: false,
	isOffline: true,
	menuVisible: false,
});
export default AppContext;
