import PropTypes from 'prop-types';

const TooltipContent = ({ children, className = '' }) => (
  <div className={`tooltip-content ${className}`}>{children}</div>
);
export default TooltipContent;

TooltipContent.displayName = 'TooltipContent';

TooltipContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
