import styled from 'styled-components';
import { darken } from 'polished';

const lineThickness = '3px';
const lineWidth = '32px';

export const StyledMenu = styled.div`
  position: relative;
`;

export const StyledMenuLine = styled.div`
  background: #ffffff;
  border-radius: 3px;
  display: block;
  height: ${({ isActive }) => (isActive ? 0 : lineThickness)};
  position: relative;
  width: ${lineWidth};
  &:before,
  &:after {
    background: #ffffff;
    border-radius: 3px;
    content: '';
    display: block;
    height: ${lineThickness};
    position: absolute;
    width: ${lineWidth};
    transition: 0.25s ease transform;
  }
  &:after {
    transform: ${({ isActive }) => (isActive ? 'rotate(135deg)' : 'translate(0, 10px)')};
  }
  &:before {
    transform: ${({ isActive }) => (isActive ? 'rotate(225deg)' : 'translate(0, -10px)')};
  }
`;

export const StyledMenuButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.primary};
  border: none;
  border-radius: 100%;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 65px;
  flex-flow: column nowrap;
  justify-content: center;
  outline: none;
  padding: 6px;
  position: relative;
  text-align: center;
  transition: 0.5s box-shadow ease;
  width: 65px;
  z-index: 101;
  &:hover {
    ${StyledMenuLine}::after {
      transform: ${({ isActive }) => !isActive && 'translate(0, 13px)'};
    }
    ${StyledMenuLine}::before {
      transform: ${({ isActive }) => !isActive && 'translate(0, -13px)'};
    }
  }
`;

export const StyledMenuOverlay = styled.div`
  background-color: ${({ theme }) => theme.primary};
  display: block;
  height: 100%;
  right: 0;
  position: fixed;
  top: 0;
  transform-origin: 96% 40px;
  transform-origin: calc(100% - 20px) 40px;
  width: 100%;
  z-index: 99;
`;

export const StyledMenuList = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: column wrap;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  ul {
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    a {
      box-sizing: border-box;
      color: #ffffff;
      display: block;
      font-size: 1.1rem;
      padding: 10px;
      text-decoration: none;
    }
  }
`;

const myListItemSize = '25px';
export const StyledMyListItem = styled.span`
  align-items: center;
  background-color: ${({ theme }) => theme.header_counter_bg || darken(0.1, theme.primary)};
  border-radius: 50%;
  box-sizing: border-box;
  color: ${({ theme }) => theme.header_counter_txt || '#ffffff'};
  display: flex;
  height: ${myListItemSize};
  font-size: 0.75rem;
  justify-content: center;
  margin-right: -8px;
  overflow: hidden;
  padding: 5px 0;
  text-align: center;
  width: ${myListItemSize};
`;
