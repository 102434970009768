import styled from 'styled-components';

export const StyledTabBar = styled.div`
  background-color: #ffffff;
  bottom: 0;
  box-shadow: -5px 0 8px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  left: 0;
  padding: 2rem 2rem 2rem 6rem;
  position: fixed;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  z-index: 6;
  transform: ${(props) => props.minimize && 'translateY(100%)'};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mobile_rg}) {
    padding: 1rem 2rem 1rem 4rem;
    @supports (padding-bottom: constant(safe-area-inset-bottom)) {
      --safe-area-inset-bottom: constant(safe-area-inset-bottom);
      padding-bottom: calc(1rem + var(--safe-area-inset-bottom));
    }
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
      --safe-area-inset-bottom: env(safe-area-inset-bottom);
      padding-bottom: calc(1rem + var(--safe-area-inset-bottom));
    }
  }
`;
