import PropTypes from 'prop-types';
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeader,
  StyledTableRow,
  StyledTableRowHeader,
} from '../../components/styled/StyledTable';
import { StyledProductSpecs } from '../../components/styled/product/StyledProductSpecs';

const ProductSpecs = ({ specs }) => (
  <StyledProductSpecs>
    <div className='specs-wrapper'>
      <StyledTable>
        <thead>
          <StyledTableRow>
            <StyledTableHeader colSpan='2'>Specifications</StyledTableHeader>
          </StyledTableRow>
        </thead>
        <tbody>
          {specs.map((spec) => (
            <StyledTableRow key={spec.spec_id}>
              <StyledTableRowHeader>{spec.label}</StyledTableRowHeader>
              <StyledTableCell>
                {spec.value.split(/\n|\\n|<br\/>/).map((item, i) => (
                  <p key={i}>{item}</p>
                ))}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </tbody>
      </StyledTable>
    </div>
  </StyledProductSpecs>
);
export default ProductSpecs;

ProductSpecs.propTypes = {
  specs: PropTypes.array.isRequired,
};
