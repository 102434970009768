import { useContext } from 'react';
import PageWrapper from './global/PageWrapper';
import AppContext from '../contexts/app/app-context';
import EndUserQuoteForm from '../forms/quotes/formTypes/EndUserQuoteForm';
import ResellerQuoteForm from '../forms/quotes/formTypes/ResellerQuoteForm';
import ResellerV2QuoteForm from '../forms/quotes/formTypes/ResellerV2QuoteForm';

// form types

const QuoteListPage = () => {
  const app = useContext(AppContext);
  const { quoteFormType } = app.state;

  const getFormByType = () => {
    switch (quoteFormType) {
      case 'enduser':
        return <EndUserQuoteForm />;
      case 'reseller_v2':
        return <ResellerV2QuoteForm />;
      case 'reseller':
      default:
        return <ResellerQuoteForm />;
    }
  };

  return (
    <PageWrapper>
      <div className='quote-request page-wrapper'>
        <h1>Request Product Information</h1>
        {getFormByType()}
      </div>
    </PageWrapper>
  );
};

export default QuoteListPage;
