import styled from 'styled-components';
import { darken, lighten } from 'polished';

const setBackgroundColor = ({ index, theme }) => {
  const percent = (index || 0) / 20;

  if (index === 0) {
    return theme.breadcrumb;
  }

  const isLightenColor = theme.breadcrumb_mode && theme.breadcrumb_mode === 'lighten';
  return isLightenColor ? lighten(percent, theme.breadcrumb) : darken(percent, theme.breadcrumb);
};

export const StyledBreadcrumbs = styled.div`
  background-color: #d3d3d3;
  border-bottom: 1px solid darken(0.05, #d3d3d3);
  display: block;
  margin: 0;
  position: relative;
  overflow: auto;
  width: 100%;
  white-space: nowrap;
  z-index: 0;
`;

export const StyledBreadcrumb = styled.span`
  background-color: ${(props) => setBackgroundColor(props)};
  box-sizing: border-box;
  box-shadow: 2px 0 6px 0px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  display: inline-block;
  font-size: 20px;
  padding: 5px 25px;
  position: relative;
  text-decoration: none;
  transition: 0.25s linear all;
  z-index: ${(props) => 10 - props.index || 10};
  &::after {
    border-top: 37px solid ${(props) => setBackgroundColor(props)};
    border-left: 0px solid transparent;
    border-right: 10px solid transparent;
    content: '';
    height: 0;
    left: 100%;
    position: absolute;
    top: 0;
    transition: 0.25s linear all;
    width: 0;
  }
  &:first-child {
    span {
      &.text {
        display: none;
      }
      &.home-icon::before {
        content: '\f015';
        font-family: 'FontAwesome';
      }
    }
  }
`;

export const StyledBreadcrumbLink = styled(StyledBreadcrumb)`
  &:hover {
    background-color: ${({ theme }) => theme.primary_action};
    &:after {
      border-top-color: ${({ theme }) => theme.primary_action};
    }
  }
`;
