import PropTypes from 'prop-types';
import { Alert, CircleInformation, StatusCritical, Validate } from 'grommet-icons';
import { Box, Paragraph } from 'grommet';

const MESSAGE_TYPE_MAP = {
  success: { icon: Validate, bg: 'status-ok' },
  error: { icon: StatusCritical, bg: 'status-error' },
  warning: { icon: Alert, bg: 'status-warning' },
  information: { icon: CircleInformation, bg: 'status-unknown' },
};

const Message = ({ message, type, show, animation }) => {
  const { icon: Icon, bg } = MESSAGE_TYPE_MAP[type] || { icon: '', bg: '' };

  return (
    <>
      {show && (
        <Box
          align='center'
          border={true}
          direction='row'
          flex={false}
          gap='small'
          round='small'
          animation={animation}
          background={{ color: bg }}
          margin={{ bottom: 'medium' }}
          pad={{ horizontal: 'small' }}
        >
          <Icon />
          <Box>
            {message.split('\n').map((msg, index) => (
              <Paragraph fill={true} key={`message-line-${index}`}>
                {msg}
              </Paragraph>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};
export default Message;

Message.defaultProps = {
  animation: 'fadeIn',
};

Message.propTypes = {
  animation: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
};
