import PropTypes from 'prop-types';
import { useContext } from 'react';
import AppContext from '../../contexts/app/app-context';
import ErrorPage from '../../pages/ErrorPage';
import LoginProtectedRoute from './LoginProtectedRoute';

const DownloadIpgProtectedRoute = ({ children }) => {
  const context = useContext(AppContext);
  const { enableDownload } = context.state;

  if (!enableDownload) {
    return <ErrorPage title='Page Does Not Exist' type='nonexistent-page' />;
  }

  return <LoginProtectedRoute>{children}</LoginProtectedRoute>;
};

export default DownloadIpgProtectedRoute;

DownloadIpgProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};
