import styled from 'styled-components';

export const StyledOptionWrapper = styled.div`
  padding: 0.5rem 0;
`;

export const StyledOptionTitle = styled.p`
  color: ${({ theme }) => theme.secondary};
  line-height: 1;
`;

export const StyledOptions = styled.div`
  display: block;
  margin-left: 10px;
`;

export const StyledOption = styled.p`
  display: inline-block;
  line-height: 1;
  margin: 0;
  padding: 5px;
  span {
    background-color: #ffffff;
    border: 1px solid #c0c0c0;
    border-radius: 1rem;
    box-sizing: border-box;
    display: block;
    min-width: 70px;
    padding: 10px;
    text-align: center;
  }
`;
