import styled from 'styled-components';

export const StyledSplashPage = styled.div`
  box-sizing: border-box;
  background-color: #ffffff;
  color: ${({ theme }) => theme.primary};
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  font-weight: 100;
  height: 100vh;
  position: relative;
  // fix for IE11
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    min-width: 1px;
    margin-top: auto;
  }
`;

export const StyledSplashPageBeginText = styled.div`
  box-sizing: border-box;
  line-height: 1.1;
  flex: 1 1 15%;
  padding: 1% 1.5%;
  span {
    display: inline-block;
    font-size: 40px;
    vertical-align: middle;
  }
  svg {
    display: inline-block;
    fill: ${({ theme }) => theme.primary};
    margin: 0 10px 0 0;
    vertical-align: middle;
    width: 110px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.tablet_rg}) {
    .touch-icon {
      width: 80px;
    }
    span {
      display: inline-block;
      font-size: 30px;
      vertical-align: middle;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mobile_rg}) {
    .touch-icon {
      width: 70px;
    }
    span {
      font-size: 24px;
      font-weight: normal;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mobile_sm}) {
    .touch-icon {
      width: 60px;
    }
    span {
      font-size: 22px;
    }
  }
  // fix for IE11
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    min-width: 1px;
    margin-top: auto;
  }
`;

export const StyledSplashPageCompanyLogo = styled.div`
  box-sizing: border-box;
  line-height: 1.1;
  display: flex;
  flex: 1 1 38%;
  margin: auto;
  min-height: 12.5rem;
  padding: 1% 1.5%;
  svg,
  img {
    height: auto;
    margin: auto;
    max-width: 600px;
    max-height: 190px;
    width: 100%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.tablet_rg}) {
    min-height: 10rem;
    flex: 1 1 30%;
    svg,
    img {
      width: 80%;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mobile_sm}) {
    min-height: 10rem;
    flex: 1 1 30%;
  }
  // fix for IE11
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    min-width: 1px;
    margin-top: auto;
  }
`;

const welcomeMsgPadding = '2.5%';
export const StyledSplashPageWelcomeMsg = styled.div`
  box-sizing: border-box;
  line-height: 1.1;
  align-content: center;
  display: flex;
  flex: 1 1 47%;
  flex-flow: column nowrap;
  font-size: 65px;
  text-align: right;
  p {
    box-sizing: border-box;
    flex: 1 1 10%;
    font-size: 4rem;
    letter-spacing: 0.2rem;
    margin: 0;
    min-height: 5.8rem;
    padding: 1% ${welcomeMsgPadding};
    // fix for IE11
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      min-width: 1px;
      margin-top: auto;
    }
  }
  h2 {
    background-color: ${({ theme }) => theme.primary};
    box-sizing: border-box;
    color: #ffffff;
    flex: 1 1 90%;
    font-size: 6.5rem;
    line-height: 1.1;
    margin: 0;
    min-height: 17.5rem;
    padding: 1.5% ${welcomeMsgPadding};
    text-transform: uppercase;
    // fix for IE11
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      min-width: 1px;
      margin-top: auto;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.tablet_rg}) {
    p {
      font-size: 3rem;
      min-height: 4.8rem;
    }
    h2 {
      font-size: 4.5rem;
      min-height: 8.5rem;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mobile_rg}) {
    p {
      font-size: 2rem;
      min-height: 3.8rem;
    }
    h2 {
      font-size: 3.5rem;
      min-height: 8.5rem;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mobile_sm}) {
    flex: 1 1 55%;
  }
  // fix for IE11
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    min-width: 1px;
    margin-top: auto;
  }
`;
