import { useContext } from 'react';
import PropTypes from 'prop-types';
import ProductAndCategoryCarousel from '../../../components/ProductAndCategoryCarousel';
import { AppSelectorContext } from '../../../contexts/appselector';

const AppSelectorOptionalResults = ({ results }) => {
  const context = useContext(AppSelectorContext);
  const { isAdmin } = context.state;

  return (
    <div className='optional'>
      <div className='result-items'>
        <p className='title'>Additional things to consider:</p>
        <ProductAndCategoryCarousel
          categoryIds={results.categories}
          productIds={results.products}
          showProductDesc={false}
          rememberSliderPosition={!isAdmin}
        />
      </div>
    </div>
  );
};

AppSelectorOptionalResults.propTypes = {
  results: PropTypes.object.isRequired,
};

AppSelectorOptionalResults.defaultProps = {
  results: {
    categories: [],
    products: [],
  },
};

export default AppSelectorOptionalResults;
