import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Loading from './Loading';

const LoadingButton = ({ children, className = '', loading, name, type, tabIndex, ...rest }) => {
  const [buttonWidth, setButtonWidth] = useState(0);
  const buttonRef = useRef(null);

  useEffect(() => {
    setButtonWidth(buttonRef.current.clientWidth);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <button
      className={`btn ${className} ${loading ? 'disabled' : ''}`}
      name={name}
      type={type}
      tabIndex={tabIndex}
      ref={buttonRef}
      {...rest}
    >
      {loading ? <Loading width={buttonWidth} /> : children}
    </button>
  );
};
export default LoadingButton;

LoadingButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  name: PropTypes.string,
  tabIndex: PropTypes.number,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};
