import { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import AppSelectorQuestion from './components/AppSelectorQuestion';
import AppSelectorResults from './components/AppSelectorResults';
import { AppSelectorContext } from '../../contexts/appselector';

const AppSelectorForm = ({ editQuestion = null }) => {
  const context = useContext(AppSelectorContext);
  const { questions, questionPath, isResult } = context.state;

  return (
    <Fragment>
      <div className='application-questions'>
        <div className='form-row flex-row'>
          {questionPath.map((qid, index) => {
            const question = questions.find((question) => question.question_id === qid);
            return (
              question && (
                <AppSelectorQuestion key={qid} question={question} index={index} editQuestion={editQuestion} />
              )
            );
          })}
        </div>
      </div>
      {isResult && <AppSelectorResults />}
    </Fragment>
  );
};

export default AppSelectorForm;

AppSelectorForm.propTypes = {
  editQuestion: PropTypes.func,
};
