import styled from 'styled-components';
import { lighten } from 'polished';

export const StyledAskTheExpert = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

export const StyledAskTheExpertBlock = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.primary};
	box-sizing: border-box;
	color: #ffffff;
	cursor: pointer;
	display: flex;
	height: auto;
	justify-content: center;
	min-height: 260px;
	overflow: hidden;
	position: relative;
	transition: 0.25s linear all;
	&.show5 {
		width: 20%;
	}
	&.show4 {
		width: 25%;
	}
	&.show3 {
		width: 33%;
	}
	&:hover {
		box-shadow: 0 0 2px rgba(0,0,0, 0.25), 0 0 10px rgba(0,0,0, 0.15);
		opacity 0.8;
		z-index: 5;
	}
	&:nth-child(even) {
		background-color: ${({ theme }) => lighten(0.05, theme.primary)};
	}
	@media only screen and (max-width: ${({ theme }) => theme.breakpoints.desktop_sm}) {
		&.show5,
		&.show4 {
			width: 33%;
		}
	}
	@media only screen and (max-width: ${({ theme }) => theme.breakpoints.table_rg}) {
		&.show5,
		&.show4,
		&.show3 {
			flex: 50%;
			width: auto;
		}
	}
	@media only screen and (max-width: ${({ theme }) => theme.breakpoints.mobile_rg}) {
		flex: 100%;
		&.show5,
		&.show4,
		&.show3 {
			flex: 100%;
			width: auto;
		}
	}
`;

export const StyledAskTheExpertBlockWithImage = styled(StyledAskTheExpertBlock)`
  &:nth-child(even) {
    background-color: #000000;
  }
  background-color: #000000;
`;

export const StyledAskTheExpertImage = styled.div`
	opacity .5;
	background-attachment: scroll;
	background-image: ${({ image }) => `url(imgs/faq/${image})`};
	background-color: #000000;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	transition: 0.25s linear all;
	width: 100%;
	z-index: 1;
`;

export const StyledAskTheExpertNumber = styled.div`
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  span {
    background-color: ${({ theme }) => theme.primary_action};
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
    color: $white;
    font-weight: bold;
    line-height: 1;
    padding: 5px 10px;
  }
`;

export const StyledAskTheExpertQuestion = styled.div`
  box-sizing: border-box;
  font-size: 1.8rem;
  padding: 1rem 0.3rem;
  position: relative;
  text-align: center;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  z-index: 2;
`;
