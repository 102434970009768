import { useContext } from 'react';
import PropTypes from 'prop-types';
import AppContext from '../../contexts/app/app-context';
import OfflineAndLoginProtectedRoute from './OfflineAndLoginProtectedRoute';
import ErrorPage from '../../pages/ErrorPage';

const QuoteListProtectedRoute = ({ children }) => {
  const context = useContext(AppContext);
  const { enableQuoting } = context.state;

  if (!enableQuoting) {
    return <ErrorPage title='Page Does Not Exist' type='nonexistent-page' />;
  }

  return <OfflineAndLoginProtectedRoute>{children}</OfflineAndLoginProtectedRoute>;
};

export default QuoteListProtectedRoute;

QuoteListProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};
