import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CatalogContext from '../../../contexts/catalog/catalog-context';
import { QUOTELIST_REMOVE } from '../../../contexts/catalog/catalog-reducer';
import Image from '../../../components/Image';

const QuoteListItem = ({ index, id, remove }) => {
  const context = useContext(CatalogContext);
  const { catalog } = context.state;
  const product = catalog.products[id];
  const productImage = product.alt_image ? product.alt_image : product.image;

  const removeItem = () => {
    remove(index);
    context.dispatch({ type: QUOTELIST_REMOVE, payload: { id: id } });
  };

  return (
    <div className='quote-list-item'>
      <Link to={`/p/${product.path}`}>
        <Image src={`imgs/products/${productImage}`} width={75} height={75} />
        <div className='product-info'>
          <span className='product-name'>{product.name}</span>
          {product.sku && <span className='product-sku'>{product.sku}</span>}
        </div>
      </Link>
      <button type='button' className='remove' onClick={removeItem}>
        <i className='fa fa-times' aria-hidden='true' />
      </button>
    </div>
  );
};

export default QuoteListItem;

QuoteListItem.propTypes = {
  index: PropTypes.number.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  remove: PropTypes.func.isRequired,
};
