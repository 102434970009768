import PropTypes from 'prop-types';
import { useContext } from 'react';
import AppContext from '../../contexts/app/app-context';
import ErrorPage from '../../pages/ErrorPage';
import LoginProtectedRoute from './LoginProtectedRoute';

const OfflineAndLoginProtectedRoute = ({ children }) => {
  const context = useContext(AppContext);
  const { isOffline } = context.state;

  if (isOffline) {
    return <ErrorPage title='Unavailable Offline' type='unavailable-offline' />;
  }

  return <LoginProtectedRoute>{children}</LoginProtectedRoute>;
};

export default OfflineAndLoginProtectedRoute;

OfflineAndLoginProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};
