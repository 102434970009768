import { Fragment, useContext } from 'react';
import AppContext from '../../../contexts/app/app-context';
import CatalogContext from '../../../contexts/catalog/catalog-context';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import QuoteForm from '../QuoteForm';

// form sections
import QuoteList from '../sections/QuoteList';
import EndUserCustomerInfo from '../sections/EndUserCustomerInfo';
import QuoteSuccessModal from '../components/QuoteSuccessModal';

const EndUserQuoteForm = () => {
  const app = useContext(AppContext);
  const context = useContext(CatalogContext);

  const { apiUrl, company } = app.state;
  const { catalog, quoteList } = context.state;

  const initialValues = {
    city: '',
    company: '',
    currentScreen: 0,
    email: '',
    firstName: '',
    formScreenComponents: [QuoteList, EndUserCustomerInfo],
    lastName: '',
    message: '',
    messageType: '',
    notes: '',
    phoneNumber: '',
    province: '',
    quoteList: quoteList,
    ipgCompany: company.name,
    showSuccess: false,
    wufooHash: 'qa9437710bud2b',
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required.'),
    lastName: Yup.string().required('Last name is required.'),
    email: Yup.string().email('Please enter a valid email address.').required('Email address is required.'),
    company: Yup.string().required('Company name is required.'),
    phoneNumber: Yup.string()
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, {
        message: 'Phone number is not in a valid format.',
        excludeEmptyString: true,
      })
      .required('Phone number is required.'),
    city: Yup.string().required('Please enter a valid city.'),
    province: Yup.string().required('Please make a selection from the dropdown.'),
  });

  const formSubmitError = (res, values, setValues) => {
    try {
      const message = res.data.message || res.request.responseText;
      setValues({ ...values, message, messageType: 'error' });
    } catch (err) {
      console.error(err);
    }
  };

  const formSubmitSuccess = (res, values, setValues) => {
    try {
      const { messageType = 'error' } = res.data;
      if (messageType !== 'success') {
        return formSubmitError(res, values, setValues);
      }
      setValues({ ...values, message: '', messageType: '', showSuccess: true });
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmit = (values, { resetForm, setSubmitting, setValues }) => {
    const productList = values.quoteList.map((id) => catalog.products[id].name);
    const fieldKeys = [
      'wufooHash',
      'firstName',
      'lastName',
      'email',
      'company',
      'phoneNumber',
      'ipgCompany',
      'city',
      'province',
      'notes',
    ];

    // get only neccessary data to post
    const formData = fieldKeys.reduce((obj, key) => ({ ...obj, [key]: values[key] }), {});
    formData['productList'] = productList.join(', ');

    // post to WuFoo
    axios
      .post(`${apiUrl}/quotes/ajax/submit-enduser-post`, formData, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((response) => formSubmitSuccess(response, values, resetForm, setValues))
      .catch(({ response }) => formSubmitError(response, values, setValues))
      .then(() => setSubmitting(false));
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(formActions) => (
        <Fragment>
          <QuoteForm formActions={formActions} />
          {formActions.values.showSuccess && <QuoteSuccessModal callback={formActions.resetForm} />}
        </Fragment>
      )}
    </Formik>
  );
};

EndUserQuoteForm.propTypes = {};

export default EndUserQuoteForm;
