import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledProductCardItem = styled.div`
  display: flex !important;
`;

export const StyledProductCardLink = styled(Link)`
  color: ${({ theme }) => theme.secondary};
  display: flex;
  text-decoration: none;
`;

export const StyledProductCard = styled.div`
  background: #fff;
  border-radius: 4px;
  flex-flow: column nowrap;
  padding: 15px;
  // fix for IE11
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    min-width: 1px;
    margin-top: auto;
  }
`;

export const StyledProductCardInfo = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.secondary};
  margin: 1rem 0;
  padding-bottom: 15px;
  text-align: left;
  ${(props) => props.hideLine && 'border-bottom: none;'}
`;

export const StyledProductCardName = styled.p`
  font-size: 22px;
  font-weight: bold;
  margin: 0 0 0.2rem;
`;

export const StyledProductCardSku = styled.p`
  font-size: 13px;
  margin: 0;
`;

export const StyledProductCardDesc = styled.div`
  font-size: 14px;
  line-height: 1.3;
  word-wrap: break-word;
  ul {
    margin: 0;
    padding-left: 20px;
  }
`;

export const StyledProductCardImage = styled.div`
  overflow: hidden;
  img {
    height: auto;
    max-width: 100%;
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55) all;
    width: auto;
  }
  ${StyledProductCard}:hover {
    img {
      transform: scale(1.1);
    }
  }
`;
