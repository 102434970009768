import PropTypes from 'prop-types';
import fallbackImg from '../../imgs/fallback.jpg';

const Image = ({ src, ...props }) => {
  const renderFallback = (ev) => (ev.target.src = fallbackImg);
  return <img src={src} onError={renderFallback} {...props} />;
};
export default Image;

Image.propTypes = {
  src: PropTypes.string.isRequired,
};
