import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { animated, useTransition } from '@react-spring/web';
import QuoteListItem from '../components/QuoteListItem';

const QuoteList = ({ values }) => {
  const { quoteList } = values || [];
  const isListEmpty = quoteList.length === 0;

  const transitions = useTransition(quoteList, {
    keys: (item) => item,
    intial: { opacity: 1, x: 0 },
    from: { opacity: 1, x: 0 },
    enter: { opacity: 1, x: 0 },
    leave: { opacity: 0.1, x: 1 },
    config: { duration: 400, friction: 12, tension: 200 },
  });

  const slideRight = (x) => `translate3d(${x * 100}%,0,0)`;

  return isListEmpty ? (
    <p>No items in your list</p>
  ) : (
    <div className='quote-list'>
      <div className='section-header'>
        <strong>Selected Products List:</strong>
      </div>
      <ul>
        <FieldArray name='quoteList'>
          {({ remove }) =>
            quoteList.length > 0 &&
            transitions((styles, item, _, index) => (
              <animated.li
                key={item.key}
                style={{
                  opacity: styles.opacity,
                  transform: styles.x.to(slideRight),
                }}
              >
                <QuoteListItem id={item} index={index} remove={remove} />
              </animated.li>
            ))
          }
        </FieldArray>
      </ul>
    </div>
  );
};

export default QuoteList;

QuoteList.propTypes = {
  values: PropTypes.object.isRequired,
};
