import PropTypes from "prop-types";
import { useReducer } from "react";
import AppContext from "./app-context";
import { appReducer, UPDATE_VALUE } from "./app-reducer";

export const AppGlobalState = (props) => {
	const { ipgApp, location } = window;

	const { modules } = ipgApp;
	const ipgAppDetails = ipgApp.info || {};
	const isOffline = location.protocol === "file:" || location.hostname === "localhost";
	const isAuthenticated = ipgApp.authenticated || isOffline;

	const [state, dispatch] = useReducer(appReducer, {
		apiUrl: ipgApp.apiUrl || "",
		appSelector: {
			answers: {},
			questionPath: [],
			isResult: false,
		},
		company: ipgApp.company || { code: "spiral", name: "Spiral", logo: "spiral-logo.svg" },
		csfr: ipgApp.csfr || { key: "csfr_token", token: "" },
		currentYear: ipgApp.currentYear || new Date().getFullYear(),
		enableApplications: modules.enableApplications,
		enableQuoting: modules.enableQuoting,
		enableDownload: modules.enableDownload,
		quoteFormType: ipgApp.quoteFormType,
		isLoggedIn: isAuthenticated,
		isOffline: isOffline,
		menuVisible: false,
	});

	const updateValue = (field, value) => {
		dispatch({
			type: UPDATE_VALUE,
			payload: { field, value },
		});
	};

	const actions = { updateValue };

	return (
		<AppContext.Provider value={{ DETAILS: ipgAppDetails, state, dispatch, actions }}>
			{props.children}
		</AppContext.Provider>
	);
};
AppGlobalState.propTypes = {
	children: PropTypes.node.isRequired,
};
