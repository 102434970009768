import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Image from '../../components/Image';
import { getRawMarkup } from '../../utilities/generic';
import { StyledTitleH1 } from '../../components/styled/StyledTitle';
import { StyledFeatureCallout } from '../../components/styled/content/StyledFeatureCallout';

const FeatureCallouts = ({ content, fallbackImage = '', product }) => {
  const { name, image } = content;
  const fallback = fallbackImage !== '' ? `imgs/${fallbackImage}` : null;
  const imgsrc = image !== null && image !== '' ? `imgs/callout/${image}` : fallback;

  return (
    <Fragment>
      <StyledTitleH1>{name.replace('#name#', product.name)}</StyledTitleH1>
      <div className='product-section callout'>
        <div className='left-col'>
          <div className='product-image'>
            <Image src={imgsrc} width={600} height={600} />
          </div>
        </div>
        <div className='right-col'>
          {content.content !== null && <StyledFeatureCallout dangerouslySetInnerHTML={getRawMarkup(content.content)} />}
        </div>
      </div>
    </Fragment>
  );
};
export default FeatureCallouts;

FeatureCallouts.propTypes = {
  content: PropTypes.shape({
    content: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  fallbackImage: PropTypes.string,
};
