import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CompanyLogo from '../components/CompanyLogo';
import AppContext from '../contexts/app/app-context';
import {
  StyledSplashPage,
  StyledSplashPageBeginText,
  StyledSplashPageCompanyLogo,
  StyledSplashPageWelcomeMsg,
} from '../components/styled/StyledSplashPage';

const SplashPage = () => {
  const context = useContext(AppContext);
  const { company } = context.state;
  const navigate = useNavigate();
  const handleClick = () => navigate('/c', { replace: true });

  return (
    <StyledSplashPage className='page' onClick={handleClick}>
      <StyledSplashPageBeginText>
        <svg
          className='touch-icon'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          x='0px'
          y='0px'
          viewBox='0 0 1000 1000'
          enableBackground='new 0 0 1000 1000'
          xmlSpace='preserve'
        >
          <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
          <g>
            <path d='M819.5,386.3h-2.5c-18.4,0-33.4,5.3-48,14.3c-12.7-37.1-45-63.7-86.6-63.7c-18.4,0-35.9,5.3-50.4,14.3c-12.7-37-45-63.7-86.6-63.7c-16.2,0-31.3,4.1-44.7,11.2v-95.3c0-51.9-39.3-94-91.6-94s-94.7,42.1-94.7,94v365l-57.5-57.3c-37-36.7-102.4-31.5-133.9,0s-52.1,94.9-7,140l264.5,262.7c5.5,5.4,11.5,9.9,17.8,13.8c48.2,39.3,103.5,62.6,220.3,62.6c266.9,0,291.6-144,291.6-321.6v-188C910.1,428.4,871.8,386.3,819.5,386.3z M860.3,668.4c0,150.3-0.7,272.1-241.8,272.1c-102.1,0-163.4-22.8-209.9-68.9L158.3,622.9c-22.2-22.2-16.6-50.5,1.6-68.6c18.1-18.1,51.4-18.8,68.9-1.4c0,0,43.9,43.7,81.8,81.3c28.6,28.5,53.8,53.5,53.8,53.5V213.2c0-24.6,20.1-44.5,44.9-44.5c24.8,0,41.7,19.9,41.7,44.5V515h0.5c-0.3,1.6-0.5,3.3-0.5,5c0,13.7,11.2,24.7,24.9,24.7c13.8,0,24.9-11.1,24.9-24.7c0-1.7-0.2-3.4-0.5-5h0.5V391.3c0-24.6,17.9-44.5,42.7-44.5c0,0,43.9-0.6,43.9,44.5v163.3h0.5c-0.3,1.6-0.5,3.3-0.5,5c0,13.7,11.2,24.7,24.9,24.7c13.8,0,24.9-11.1,24.9-24.7c0-1.7-0.2-3.3-0.5-5h0.5V440.8c0-24.6,17.6-44.5,42.4-44.5c0,0,44.8,2.8,44.8,44.5v143.5h0.5c-0.3,1.6-0.5,3.3-0.5,4.9c0,13.7,11.2,24.7,24.9,24.7c13.8,0,24.3-11.1,24.3-24.7c0-1.7-0.2-3.3-0.5-4.9h0.5v-98c0-24.6,18.5-44.5,43.3-44.5c0,0,43.4-1.8,43.4,44.5C860.3,486.3,860.3,628.4,860.3,668.4z M277.5,343.4v-83.1c-7.9-17.7-12.4-37.3-12.4-58c0-78.8,63.9-142.7,142.7-142.7s142.7,63.9,142.7,142.7c0,10.8-1.3,21.2-3.6,31.3c17.9,0.7,34.1,8.1,45.9,20.1c4.6-16.4,7.3-33.5,7.3-51.4C600,96.1,513.9,10,407.7,10S215.4,96.1,215.4,202.3C215.4,258.1,239.4,308.3,277.5,343.4z' />
          </g>
        </svg>
        <span>
          Touch or click
          <br />
          the screen to begin.
        </span>
      </StyledSplashPageBeginText>
      <StyledSplashPageCompanyLogo>
        <CompanyLogo src={company.logo} companyName={company.name} />
      </StyledSplashPageCompanyLogo>
      <StyledSplashPageWelcomeMsg>
        <p>Welcome to our</p>
        <h2>
          <span>
            Product
            <br />
            Guide
          </span>
        </h2>
      </StyledSplashPageWelcomeMsg>
    </StyledSplashPage>
  );
};
export default SplashPage;
