import styled from 'styled-components';
import { darken } from 'polished';

export const StyledQuoteButtons = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.tablet_rg}) {
    justify-content: space-between;
    margin-top: 1rem;
    padding-top: 0;
  }
`;

export const StyledQuoteButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.primary_action};
  border: 0.1rem solid ${({ theme }) => darken(0.05, theme.primary_action)};
  border-radius: 3rem;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  height: 40px;
  font-size: 1rem;
  justify-content: center;
  outline: none;
  margin: 0.2rem 0.4rem;
  padding: 0 1rem;
  text-decoration: none;
  transition: 0.25s linear all;
  &:hover {
    opacity: 0.8;
  }
  &:before {
    content: '\f067';
    font-family: 'FontAwesome';
  }
  span {
    box-sizing: border-box;
    margin: 0 0.4rem;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.large_screen}) {
    width: 180px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.tablet_rg}) {
    border-radius: 0.5rem;
    flex: 1;
    height: 45px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mobile_rg}) {
    font-size: 0.8rem;
    padding: 0;
  }
`;

export const StyledAddedQuoteButton = styled(StyledQuoteButton)`
  background-color: #19ad84;
  border: 0.1rem solid ${darken(0.05, '#19ad84')};
  &:before {
    content: '\f00c';
  }
`;

export const StyledRemoveQuoteButton = styled(StyledQuoteButton)`
  background-color: #999999;
  border: 0.1rem solid ${darken(0.05, '#999999')};
  &:before {
    content: '\f00d';
  }
`;
