import PropTypes from 'prop-types';
import PageWrapper from './global/PageWrapper';
import ErrorMessage from '../components/ErrorMessage';

const ErrorPage = ({ children, className = '', type, title = '' }) => (
  <PageWrapper isErrorPage={true}>
    <div className={`error-page page-wrapper ${className || ''}`}>
      <ErrorMessage type={type} title={title || ''} />
      {children}
    </div>
  </PageWrapper>
);

export default ErrorPage;
ErrorPage.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
};
