import { useContext } from 'react';
import PropTypes from 'prop-types';
import CatalogContext from '../../../contexts/catalog/catalog-context';
import { QUOTELIST_CLEARALL } from '../../../contexts/catalog/catalog-reducer';
import { StyledSecondaryOutlineActionButton, StyledClearAllButton } from '../../../components/styled/StyledActionBar';

const QuoteActionBar = ({ values, updateValue }) => {
  const context = useContext(CatalogContext);
  const { currentScreen, formScreenComponents, quoteList } = values;

  // button actions
  const nextScreen = () => updateValue('currentScreen', currentScreen + 1);
  const prevScreen = () => currentScreen > 0 && updateValue('currentScreen', currentScreen - 1);
  const clearAll = () => {
    context.dispatch({ type: QUOTELIST_CLEARALL });
    updateValue('quoteList', []);
  };

  // button display checks
  const hasItems = quoteList.length > 0;
  const showBackBtn = currentScreen > 0;
  const showClearBtn = currentScreen === 0;
  const showContinueBtn = currentScreen + 1 !== formScreenComponents.length;

  return (
    hasItems && (
      <div className='action-bar'>
        {showBackBtn && (
          <StyledSecondaryOutlineActionButton className='prevbtn' onClick={prevScreen}>
            <span>Back</span>
          </StyledSecondaryOutlineActionButton>
        )}
        <div className='group-btns right'>
          {showClearBtn && (
            <StyledClearAllButton onClick={clearAll}>
              <span>Clear All</span>
            </StyledClearAllButton>
          )}
          {showContinueBtn && (
            <StyledSecondaryOutlineActionButton className='nextbtn' onClick={nextScreen}>
              Continue
            </StyledSecondaryOutlineActionButton>
          )}
        </div>
      </div>
    )
  );
};

export default QuoteActionBar;

QuoteActionBar.propTypes = {
  values: PropTypes.object.isRequired,
  updateValue: PropTypes.func.isRequired,
};
