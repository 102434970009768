import { useEffect, useState } from 'react';

const initialValues = {
  visible: false,
  allowEscClose: true,
};

const useModal = (userConfig = {}) => {
  // get configuration
  const { visible, allowEscClose } = { ...initialValues, ...userConfig };

  const [isVisible, setIsVisible] = useState(visible);
  const openModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);
  const toggleModal = () => (isVisible ? closeModal() : openModal());

  const ESCAPE_KEY = 27;
  const handleKeyDown = (ev) => {
    switch (ev.keyCode) {
      case ESCAPE_KEY:
        closeModal();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isVisible) {
      allowEscClose && document.addEventListener('keydown', handleKeyDown);
      document.body.classList.add('noscroll');
    }
    return () => {
      allowEscClose && document.removeEventListener('keydown', handleKeyDown);
      document.body.classList.remove('noscroll');
    };
  }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  return { closeModal, openModal, isVisible, toggleModal };
};

export default useModal;
