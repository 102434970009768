import { useLocation, useNavigate } from 'react-router-dom';
import { StyledBackButton } from '../../components/styled/StyledBackButton';

const BackButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let locked = false;
  const goBack = () => {
    if (locked === false) {
      locked = true;
      navigate(-1);
    }
  };
  return location.pathname !== '/c' && <StyledBackButton onClick={goBack} />;
};

export default BackButton;
