import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Image from '../../components/Image';

const CategoryCard = ({ allowClicks, className, category }) => {
  const toLink = `/c/${category.path}`;

  const handleClick = (ev) => {
    if (!allowClicks) {
      ev.preventDefault();
      ev.stopPropagation();
    }
  };

  return (
    <div className={`category-item ${className}`}>
      <Link className='category-link' onClick={handleClick} to={toLink} data-categoryid={category.category_id}>
        <div className='category-card'>
          <div className='category-image'>
            <Image src={`imgs/category/${category.image}`} width={600} height={600} />
          </div>
          <div className='category-info'>
            <p className='category-name'>{category.name}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default CategoryCard;

CategoryCard.propTypes = {
  allowClicks: PropTypes.bool.isRequired,
  className: PropTypes.string,
  category: PropTypes.object.isRequired,
};

CategoryCard.defaultProps = {
  allowClicks: true,
  className: '',
};
