import styled from 'styled-components';

export const StyledProductWrapper = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.tablet_rg}) {
    display: block;
    .left-col,
    .right-col {
      display: block;
    }
    .left-col {
      .product-image {
        display: block;
      }
    }
  }
`;

export const StyledProductDetails = styled.div`
  color: ${({ theme }) => theme.secondary};
  flex-grow: 3;
  margin: 20px 10px;
`;

export const StyledProductHeading = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.secondary};
  margin-bottom: 1rem;
  padding-bottom: 15px;
  text-align: left;
`;

export const StyledProductName = styled.p`
  font-size: 2rem;
  font-weight: 700;
  margin: 0 0 0.2rem;
`;

export const StyledProductSku = styled.p`
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0;
`;

export const StyledProductDescription = styled.div`
  font-size: 0.9rem;
  line-height: 1.4;
  overflow: auto;
  word-wrap: break-word;
  ul {
    margin: 0;
    padding-left: 20px;
  }
`;

export const StyledProductSpecWrapper = styled.div`
  flex-grow: 3;
  margin: 0 10px;
`;
