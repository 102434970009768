import styled from 'styled-components';
import Slider from 'react-slick';
import { darken } from 'polished';

export const StyledSlider = styled(Slider)`
  margin-bottom: 30px; // accomodate for dots
  .slick-arrow {
    background: transparent;
    display: block;
    height: 50px;
    overflow: hidden;
    outline: none;
    opacity: 1;
    padding: 0;
    position: absolute;
    visibility: visible;
    transition: opacity linear 0.25s, visibility linear 0.25s;
    width: 50px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 10;
    &:before {
      color: ${({ theme }) => theme.secondary};
      font-size: 50px;
      margin: 0 20px 0 2px;
      text-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    }
    &[disabled] {
      opacity: 0 !important;
      visibility: hidden;
      pointer-events: none;
    }
    &.slick-prev {
      left: -23px;
      &:before {
        content: '\f137';
        font-family: 'FontAwesome';
      }
    }
    &.slick-next {
      right: -27px;
      &:before {
        content: '\f138';
        font-family: 'FontAwesome';
      }
    }
  }
  .slick-dots {
    button {
      &:before {
        color: ${({ theme }) => darken(0.15, theme.secondary)};
        font-size: 8px;
      }
    }
  }
  .slick-list {
    overflow: visible;
    .slick-track {
      display: flex;
      box-sizing: border-box;
      .slick-slide {
        background-color: #ffffff;
        border-radius: 4px;
        box-shadow: 0 0 45px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.3);
        display: flex;
        height: auto;
        margin: 20px 26px;
        & > div {
          display: flex;
        }
      }
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.tablet_rg}) {
    padding: 0 40px;
    .slick-dots {
      transform: translateX(-40px);
    }
    .slick-list .slick-track .slick-slide {
      margin: 20px;
    }
  }
`;
