import { useTransition } from '@react-spring/web';
import PropTypes from 'prop-types';
import { Children, isValidElement, useState } from 'react';
import { StyledTabContent, StyledTabSwitcher, StyledTabWrapper } from '../styled/tabs';
import TabsList from './TabsList';

const Tabs = ({ children, className = '' }) => {
  const [activeTab, setActiveTab] = useState(0);
  const switchTabs = (index) => setActiveTab(index);
  const transitions = useTransition(activeTab, {
    keys: (item) => item,
    initial: { y: 0, opacity: 1 },
    from: { y: 100, opacity: 0.5 },
    enter: { y: 0, opacity: 1 },
    leave: { y: 100, opacity: 0.3 },
  });

  let validChildren = Children.toArray(children).filter((child) => isValidElement(child));

  return (
    <StyledTabWrapper className={className}>
      <StyledTabSwitcher>
        {transitions((styles, item) => (
          <StyledTabContent
            key={item.key}
            style={{
              height: styles.y.to((y) => (y === 0 ? 'auto' : '100%')),
              position: styles.y.to((y) => (y === 0 ? 'relative' : 'absolute')),
              opacity: styles.opacity.to((opacity) => opacity),
              overflow: styles.y.to((y) => (y === 0 ? 'auto' : 'hidden')),
              transform: styles.y.to((y) => `translate(0%, ${y}%)`),
              width: styles.y.to((y) => (y === 0 ? 'auto' : '100%')),
            }}
          >
            {validChildren[item].props.children}
          </StyledTabContent>
        ))}
      </StyledTabSwitcher>
      {Children.count(validChildren) > 1 && (
        <TabsList activeTab={activeTab} switchTabs={switchTabs}>
          {validChildren}
        </TabsList>
      )}
    </StyledTabWrapper>
  );
};
export default Tabs;

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
