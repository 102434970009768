import PropTypes from 'prop-types';

const Select = ({ blank, field, label, onChange, options, optKey, optVal, ...props }) => {
  const handleChange = onChange || field.onChange || null;

  return (
    <label>
      {label && <span className='label-text'>{label}</span>}
      <div className='select-wrapper'>
        <select {...field} {...props} onChange={handleChange}>
          {blank && <option value='' />}
          {options.map((option) => (
            <option key={option[optKey]} value={option[optKey]}>
              {option[optVal]}
            </option>
          ))}
        </select>
      </div>
    </label>
  );
};

export default Select;

Select.propTypes = {
  blank: PropTypes.bool,
  field: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  optKey: PropTypes.string,
  optVal: PropTypes.string,
};

Select.defaultProps = {
  blank: true,
  optKey: 'id',
  optVal: 'value',
};
